function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  // Position the textarea off-screen to prevent viewport shifts
  textArea.style.position = "absolute";
  textArea.style.top = "50%";
  textArea.style.bottom = "50%";
  textArea.style.left = "50%";
  textArea.style.right = "50%";

  // Ensure the textarea is as unobtrusive as possible
  textArea.style.width = "1px";
  textArea.style.height = "0.02px";
  textArea.style.padding = "0";
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.style.opacity = "0";
  textArea.style.pointerEvents = "none";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  return navigator.clipboard.writeText(text);
}
