import { Stack } from "@ui/layout";
import { InfoCard } from "@components/IconCard/IconCard";
import { ReadmeContent } from "@components/ReadmeContent/ReadmeContent";
import { useFixtureContext } from "@components/FixtureCard/context";

function getColorByPredictability(
  predictability?: number,
): "danger" | "warning" | "success" | undefined {
  if (!predictability) {
    return undefined;
  }

  if (predictability <= 5) {
    return "danger";
  } else if (predictability <= 7) {
    return "warning";
  } else {
    return "success";
  }
}

export const MatchPreview = () => {
  const {
    source: { data },
  } = useFixtureContext();

  return (
    <Stack>
      <h4 className="sr-only">Match Preview</h4>
      <Stack
        direction="horizontal"
        align="center"
        spacing="xs"
        className="w-full"
      >
        <InfoCard
          icon="Target"
          iconAlt="Target icon representing predictability"
          value={data?.data.summary.predictability}
          valueSubtext="/10"
          label="Predictability"
          color={getColorByPredictability(data?.data.summary.predictability)}
          className="w-1/2 min-w-0"
        />
        <InfoCard
          icon={data?.data.summary.safe_for_betting ? "ShieldCheck" : "X"}
          iconAlt={
            data?.data.summary.safe_for_betting
              ? "Shield icon representing safeness"
              : "X icon representing risk of the fixture in terms of betting"
          }
          value={data?.data.summary.safe_for_betting ? "Yes" : "No"}
          label="Safe for Betting"
          color={data?.data.summary.safe_for_betting ? "success" : "warning"}
          className="w-1/2 min-w-0"
        />
      </Stack>
      <ReadmeContent
        className="prose prose-sm prose-slate dark:prose-invert"
        content={data?.data.match_preview}
      />
    </Stack>
  );
};
