import { useEffect, useState } from "react";

export const getUtmParams = () => {
  const safeGetFromLocalStorage = (key: string): string | null => {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  };

  const safeSetToLocalStorage = (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch {
      // Fail silently if localStorage isn't available
    }
  };

  const queryParams =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams(); // Handle SSR gracefully

  const utm_source =
    queryParams.get("utm_source") ||
    safeGetFromLocalStorage("utm_source") ||
    "unknown";

  const utm_medium =
    queryParams.get("utm_medium") ||
    safeGetFromLocalStorage("utm_medium") ||
    "unknown";

  const utm_campaign =
    queryParams.get("utm_campaign") ||
    safeGetFromLocalStorage("utm_campaign") ||
    "unknown";

  // Only set known values to localStorage
  if (utm_source !== "unknown") {
    safeSetToLocalStorage("utm_source", utm_source);
  }
  if (utm_medium !== "unknown") {
    safeSetToLocalStorage("utm_medium", utm_medium);
  }
  if (utm_campaign !== "unknown") {
    safeSetToLocalStorage("utm_campaign", utm_campaign);
  }

  return {
    utm_source,
    utm_medium,
    utm_campaign,
  };
};

export const createLinkWithUtm = (url: string) => {
  const { utm_source, utm_medium, utm_campaign } = getUtmParams();

  try {
    const targetUrl = new URL(url, window.location.origin);

    // If you prefer not to append unknown parameters, you can conditionally add them only if they are not "unknown".
    targetUrl.searchParams.set("utm_source", utm_source);
    targetUrl.searchParams.set("utm_medium", utm_medium);
    targetUrl.searchParams.set("utm_campaign", utm_campaign);

    return targetUrl.toString();
  } catch {
    // If url is invalid, just return it as-is.
    // In a production scenario, you might log this error.
    return url;
  }
};

export function useUtmLink(originalUrl: string): string {
  const [linkUrl, setLinkUrl] = useState(originalUrl);

  useEffect(() => {
    const updatedUrl = createLinkWithUtm(originalUrl);
    setLinkUrl(updatedUrl);
  }, [originalUrl]);

  return linkUrl;
}
