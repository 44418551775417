"use client";

import Script from "next/script";

export const CookieYes = () => {
  return (
    <Script
      id="cookieyes"
      type="text/javascript"
      strategy="worker"
      src="https://cdn-cookieyes.com/client_data/aeeb15ba5994fa90342ca27e/script.js"
    />
  );
};
