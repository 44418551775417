"use client";

import { Stack } from "@ui/layout";
import Image from "next/image";
import logo from "./logo.png";
import Link from "next/link";
import { Button, Icon } from "@ui/elements";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { ThemeSwitcher } from "@components/ThemeSwitcher/ThemeSwitcher";
import { MobileMenu } from "@components/Navigation/MobileMenu";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

export const Navigation = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastHidePosition, setLastHidePosition] = useState(0);
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");
  const scrollThresholdDown = 60;
  const scrollThresholdUp = 40;
  let isScrolling = false;

  const handleScroll = () => {
    if (isScrolling) return;
    isScrolling = true;
    requestAnimationFrame(() => {
      const currentScrollY = window.scrollY;
      if (
        currentScrollY > lastScrollY &&
        currentScrollY - lastHidePosition > scrollThresholdDown &&
        currentScrollY > 100
      ) {
        setIsVisible(false);
        setIsMenuOpen(false);
        setLastHidePosition(currentScrollY);
      } else if (
        lastScrollY > currentScrollY &&
        lastHidePosition - currentScrollY > scrollThresholdUp
      ) {
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
      isScrolling = false;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY, lastHidePosition]);

  return (
    <header
      className={classNames(
        "fixed top-3 z-50 w-full scale-100 px-3 transition-all md:top-10 lg:top-8",
        {
          "-translate-y-full scale-95 opacity-0": !isVisible,
        },
      )}
    >
      <div className="container relative mx-auto flex items-center justify-between rounded-full bg-white px-0.5 py-2 shadow-sm ring-1 ring-gray-200 xl:px-xxs xl:py-3 dark:bg-neutral-800 dark:ring-neutral-700">
        <Link href="/" aria-label="Go to homepage">
          <Stack spacing="xxs" direction="horizontal" align="center">
            <Image
              src={logo}
              alt="Football Genie AI – Smarter Football Betting Tips"
              priority
              className="-my-4 aspect-square size-14 rounded-full p-1 xl:size-20"
              width={80}
              height={80}
            />
            <p className="flex items-center gap-1 xl:gap-1.5">
              <span className="text-sm font-semibold xl:text-lg dark:text-neutral-300">
                Football Genie
              </span>{" "}
              <span className="inline-block bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-sm font-bold text-transparent xl:text-xl">
                AI
              </span>
            </p>
          </Stack>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden lg:block" aria-label="Main navigation">
          <ul className="flex gap-7 xl:gap-8">
            <li>
              <Link
                className="group relative block px-2 transition-all before:absolute before:left-0 before:top-full before:h-0.5 before:w-0 before:bg-primary before:transition-all hover:-translate-y-0.5 hover:before:w-full xl:text-lg dark:text-neutral-300"
                href="#how-it-works"
                aria-label="Learn How It Works"
                onClick={() => {
                  sendGTMEvent({
                    event: "link_click",
                    value: "how_it_works",
                    ...getUtmParams(),
                  });
                }}
              >
                How it works
              </Link>
            </li>
            <li>
              <Link
                className="group relative block px-2 transition-all before:absolute before:left-0 before:top-full before:h-0.5 before:w-0 before:bg-primary before:transition-all hover:-translate-y-0.5 hover:before:w-full xl:text-lg dark:text-neutral-300"
                href="#features"
                aria-label="Explore Features"
                onClick={() => {
                  sendGTMEvent({
                    event: "link_click",
                    value: "features",
                    ...getUtmParams(),
                  });
                }}
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                className="group relative block px-2 transition-all before:absolute before:left-0 before:top-full before:h-0.5 before:w-0 before:bg-primary before:transition-all hover:-translate-y-0.5 hover:before:w-full xl:text-lg dark:text-neutral-300"
                href="#pricing"
                aria-label="View Pricing Options"
                onClick={() => {
                  sendGTMEvent({
                    event: "link_click",
                    value: "pricing",
                    ...getUtmParams(),
                  });
                }}
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                className="group relative block px-2 transition-all before:absolute before:left-0 before:top-full before:h-0.5 before:w-0 before:bg-primary before:transition-all hover:-translate-y-0.5 hover:before:w-full xl:text-lg dark:text-neutral-300"
                href="#contact"
                aria-label="Contact Us"
                onClick={() => {
                  sendGTMEvent({
                    event: "link_click",
                    value: "contact",
                    ...getUtmParams(),
                  });
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        {/* Desktop ThemeSwitcher and Button */}
        <Stack
          direction="horizontal"
          spacing="sm"
          align="center"
          className="hidden lg:flex"
        >
          <div className="size-10 lg:size-14">
            <ThemeSwitcher />
          </div>

          <Button
            color="primary"
            size="medium"
            isRounded
            className="mr-xs bg-gradient-to-r from-primary-400 to-primary-600 p-9 py-3.5 font-semibold xl:px-11 xl:text-lg"
            as="link"
            href={dashboardLink}
            title="Get AI-driven football predictions and analysis"
            aria-label="Get AI-driven football predictions and analysis"
            onClick={() =>
              sendGTMEvent({
                event: "cta_click",
                value: "navbar_predictions",
                ...getUtmParams(),
              })
            }
          >
            <Stack direction="horizontal" spacing="xs" align="center">
              <span>Predictions</span>
              <Icon icon="CaretRight" weight="bold" className="-mr-3 size-6" />
            </Stack>
          </Button>
        </Stack>

        {/* Mobile Menu */}
        <MobileMenu open={isMenuOpen} setOpen={setIsMenuOpen} />
      </div>
    </header>
  );
};
