"use client";

import { Stack } from "@ui/layout";
import { Button, Icon, Tag, Title } from "@ui/elements";
import { GetTipsPerformanceParams, useGetTipsPerformance } from "@api";
import dayjs from "dayjs";
import React, { useCallback, useRef, useState, useEffect } from "react";
import classNames from "classnames";

import GridPattern from "@components/ui/grid-pattern";
import { cn } from "@/lib/utils";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useDeviceType } from "@hooks/useDeviceType";
import { FixtureCard } from "@components/FixtureCard/FixtureCard";
import { Container } from "@components/Container/Container";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

export const RecentFixtures = () => {
  const swiperInstance = useRef<SwiperRef>(null);
  const navigationPrevRef = useRef<HTMLButtonElement>(null);
  const navigationNextRef = useRef<HTMLButtonElement>(null);
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");

  const [navigationInitialized, setNavigationInitialized] = useState(false);

  const query: GetTipsPerformanceParams = {
    type: "resultOriented",
    sort: "desc",
    page: 1,
    pageSize: 5,
    from: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
  };
  const { data: tipsData } = useGetTipsPerformance(query);
  const deviceType = useDeviceType();

  const handleTabChange = useCallback(() => {
    if (swiperInstance?.current?.swiper) {
      swiperInstance.current.swiper.updateAutoHeight(
        deviceType === "desktop" ? 150 : 0,
      );
    }
  }, [swiperInstance, deviceType]);

  useEffect(() => {
    // Trigger re-render to pass navigation elements once refs are set
    setNavigationInitialized(true);
  }, []);

  return (
    <Stack className="relative px-6 py-20 md:py-32" id="latest-predictions">
      <div className="absolute left-0 top-0 z-10 h-20 w-full bg-gradient-to-b to-transparent dark:from-neutral-900" />
      <div className="absolute bottom-0 left-0 z-10 h-20 w-full bg-gradient-to-t to-transparent dark:from-neutral-900" />
      <GridPattern
        width={30}
        height={30}
        x={-1}
        y={-1}
        strokeDasharray={"4 2"}
        className={cn(
          "[mask-image:radial-gradient(50rem_circle_at_center,white,transparent)]",
        )}
      />

      <Container>
        <div className="mx-auto flex flex-col items-center gap-lg xl:flex-row-reverse xl:gap-xxl">
          <Stack
            className="max-w-3xl xl:max-w-xl"
            spacing="md"
            data-aos="fade-up"
          >
            <Tag size="md" variant="neutral">
              AI Performance
            </Tag>
            <Stack spacing="xxl">
              <Stack spacing="xs">
                <Title as="h2" styleAs="h1">
                  Latest Predictions
                </Title>
                <Title
                  as="h3"
                  type="subtitle"
                  color="tertiary"
                  className="font-light leading-relaxed tracking-wide"
                >
                  Get a taste of what Football Genie delivers. Accurate
                  predictions, reliable insights, and a proven track betting
                  record awaits you.
                </Title>
              </Stack>

              <Button
                color="primary"
                size="medium"
                className="hidden xl:flex"
                as="link"
                data-aos="fade-up"
                href={process.env.NEXT_PUBLIC_APP_URL || ""}
                tabIndex={0}
                aria-label="View and explore upcoming AI football tips and predictions"
                onClick={() =>
                  sendGTMEvent({
                    event: "cta_click",
                    value: "latest_predictions",
                    ...getUtmParams(),
                  })
                }
              >
                Upcoming Tips
              </Button>
            </Stack>
          </Stack>
          <Stack
            align="center"
            className="relative max-w-[100vw] flex-1 md:max-w-full lg:max-w-3xl"
          >
            <nav
              aria-label="Latest Predictions slider navigation"
              className="relative z-10 flex w-full justify-end gap-md pr-2"
            >
              <Button
                ref={navigationPrevRef}
                aria-label="Previous fixture"
                className={classNames(
                  "h-8 bg-white ring-1 ring-gray-200 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-700",
                )}
              >
                <Icon icon="CaretLeft" className="size-6" />
                <span className="sr-only">Previous fixture</span>
              </Button>
              <Button
                ref={navigationNextRef}
                aria-label="Next fixture"
                className={classNames(
                  "h-8 bg-white ring-1 ring-gray-200 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-700",
                )}
              >
                <Icon icon="CaretRight" className="size-6" />
                <span className="sr-only">Next fixture</span>
              </Button>
            </nav>
            <Swiper
              spaceBetween={50}
              modules={[Navigation]}
              navigation={
                navigationInitialized
                  ? {
                      nextEl: navigationNextRef.current,
                      prevEl: navigationPrevRef.current,
                    }
                  : false
              }
              a11y={{
                prevSlideMessage: "Previous fixture",
                nextSlideMessage: "Next fixture",
                slideLabelMessage: "{{index}} / {{slidesLength}}",
              }}
              onSwiper={(swiper) => {
                if (
                  navigationPrevRef.current &&
                  navigationNextRef.current &&
                  !navigationInitialized
                ) {
                  if (typeof swiper.params?.navigation === "object") {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                  }
                  swiper.navigation.init();
                  swiper.navigation.update();
                  setNavigationInitialized(true);
                }
              }}
              ref={swiperInstance}
              slidesPerView={1}
              loop={false}
              allowTouchMove={deviceType === "desktop"}
              autoHeight={true}
              className="w-full"
            >
              {tipsData?.data.data.map((match) => (
                <SwiperSlide key={`recent-fixture-${match.fixture.fixture.id}`}>
                  <div className="w-full flex-none p-2">
                    <FixtureCard data={match} onTabChange={handleTabChange} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex w-full justify-end px-2 xl:hidden">
              <Button
                color="primary"
                size="medium"
                as="link"
                href={dashboardLink}
                tabIndex={0}
                aria-label="View and explore upcoming AI football tips and predictions"
                onClick={() =>
                  sendGTMEvent({
                    event: "cta_click",
                    value: "latest_predictions",
                    ...getUtmParams(),
                  })
                }
              >
                Upcoming Tips
              </Button>
            </div>
          </Stack>
        </div>
      </Container>
    </Stack>
  );
};
