import { FC, ReactNode, useRef, useEffect } from "react";
import { Icon, Icons } from "@ui/elements";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

type TabItem = {
  name: string;
  label: string;
  icon?: Icons;
  content: ReactNode | ReactNode[];
  highlighted?: boolean;
};

type TabProps = {
  items: TabItem[];
  value?: string;
  onChange?: (tab: string) => void;
  onContentUpdate?: () => void;
  tabId?: string;
  contentClass?: string;
  asideClass?: string;
};

export const Tab: FC<TabProps> = ({
  value,
  onChange,
  items,
  tabId = "default-tab",
  onContentUpdate,
  contentClass,
  asideClass,
}) => {
  const sortedItems = items.slice().sort((a, b) => {
    if (a.highlighted === b.highlighted) return 0;
    return a.highlighted ? 1 : -1;
  });
  const tabMenu = useRef<HTMLDivElement>(null);

  const activeTab = value || sortedItems[0].name;

  const setActiveTab = (tabName: string) => {
    onChange?.(tabName);
  };

  // Get the current and previous tab indices
  const activeTabIndex = sortedItems.findIndex((tab) => tab.name === activeTab);
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  // Determine the direction of the animation
  let direction = 1;
  if (previousActiveTabIndex !== undefined) {
    if (activeTabIndex > previousActiveTabIndex) {
      direction = 1; // Slide up
    } else if (activeTabIndex < previousActiveTabIndex) {
      direction = -1; // Slide down
    } else {
      direction = 0; // No movement
    }
  }

  const variants = {
    initial: (direction: number) => ({ opacity: 0, y: 20 * direction }),
    animate: { opacity: 1, y: 0 },
    exit: (direction: number) => ({ opacity: 0, y: -20 * direction }),
  };

  const normalItems = sortedItems.filter((item) => !item.highlighted);
  const highlightedItems = sortedItems.filter((item) => item.highlighted);

  const handleTabClick = (button: HTMLButtonElement, tab: TabItem) => {
    setActiveTab(tab.name);
    if (!tabMenu.current) return;

    const buttonRect = button.getBoundingClientRect();
    const menuRect = tabMenu.current.getBoundingClientRect();

    const offset = buttonRect.left - menuRect.left + tabMenu.current.scrollLeft;

    tabMenu.current.scrollTo({
      left: offset,
      behavior: "smooth",
    });

    setTimeout(() => {
      onContentUpdate?.();
    });
  };

  const renderTabButton = (tab: TabItem) => {
    const isActive = activeTab === tab.name;
    const tabButtonId = `${tabId}-tab-${tab.name}`;
    const tabPanelId = `${tabId}-panel-${tab.name}`;

    return (
      <button
        key={`${tab.name}-menu-item`}
        id={tabButtonId}
        role="tab"
        aria-selected={isActive}
        aria-controls={tabPanelId}
        tabIndex={isActive ? 0 : -1}
        onClick={(event) =>
          handleTabClick(event.currentTarget as HTMLButtonElement, tab)
        }
        className={`relative flex items-center gap-xs whitespace-nowrap text-nowrap rounded-md px-3 py-2 text-left text-sm transition-all md:min-w-44 dark:text-neutral-300`}
      >
        {tab.icon && (
          <Icon
            weight="regular"
            icon={tab.icon}
            className="relative z-10 size-5"
            aria-hidden="true"
          />
        )}
        <span className="relative z-10 whitespace-nowrap text-nowrap">
          {tab.label}
        </span>
        {isActive && (
          <motion.div
            layoutId={`activeTabIndicator-${tabId}`}
            className="absolute inset-0 rounded-md bg-gray-200 dark:bg-neutral-800"
            style={{ zIndex: 0 }}
            aria-hidden="true"
          />
        )}
      </button>
    );
  };

  return (
    <div className="flex w-full flex-col gap-md md:flex-row">
      <div
        className={cn("flex gap-md overflow-auto md:flex-col", asideClass)}
        ref={tabMenu}
        role="tablist"
        aria-orientation="horizontal"
      >
        {normalItems.map(renderTabButton)}
        {highlightedItems.length > 0 && (
          <div
            className="hidden h-full min-h-20 w-full border-b border-gray-200 md:block dark:border-neutral-700"
            aria-hidden="true"
          />
        )}
        {/* Spacer */}
        {highlightedItems.map(renderTabButton)}
      </div>

      <div className="flex-1">
        {/* Render all tab panels, but hide inactive ones */}
        {sortedItems.map((tab) => {
          const isActive = tab.name === activeTab;
          return (
            <motion.div
              key={tab.name}
              initial={isActive ? "initial" : false}
              animate={isActive ? "animate" : "exit"}
              variants={variants}
              custom={direction}
              transition={{ duration: 0.2 }}
              className={cn(
                "rounded-lg bg-gray-50 p-4 dark:bg-neutral-800",
                { block: isActive, "sr-only": !isActive },
                contentClass,
              )}
              role="tabpanel"
              id={`${tabId}-panel-${tab.name}`}
              aria-labelledby={`${tabId}-tab-${tab.name}`}
              tabIndex={0}
            >
              <div>{tab.content}</div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
