import React from "react";
import { cn } from "@/lib/utils";

type Spacings =
  | "unset"
  | "none"
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "xxxl";

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The content of the stack */
  children: React.ReactNode;
  /** The direction of the stack */
  direction?: "vertical" | "horizontal";
  /** The alignment of the items */
  align?: "start" | "center" | "end" | "stretch" | "baseline";
  /** The spacing between the items (Tailwind spacing scale) */
  spacing?: Spacings;
  /** Additional CSS classes */
  className?: string;
  /** If true, the stack will wrap its items to the next line */
  wrap?: boolean;
  as?: string;
}

export const Stack: React.FC<StackProps> = ({
  children,
  direction = "vertical",
  align = "start",
  spacing = "sm",
  className = "",
  wrap = false,
  ...props
}) => {
  const flexDirection = direction === "vertical" ? "flex-col" : "flex-row";
  const alignment = {
    start: "items-start",
    center: "items-center",
    end: "items-end",
    stretch: "items-stretch",
    baseline: "items-baseline",
  }[align];

  const spacingClasses: Record<Spacings, string> = {
    unset: "",
    xxs: "gap-xxs",
    xs: "gap-xs",
    sm: "gap-sm",
    md: "gap-md",
    lg: "gap-lg",
    xl: "gap-xl",
    xxl: "gap-xxl",
    xxxl: "gap-xxxl",
    none: "gap-none",
  };

  const wrapClass = wrap ? "flex-wrap" : "";

  return (
    <div
      className={cn(
        "flex",
        flexDirection,
        alignment,
        spacingClasses[spacing],
        wrapClass,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
