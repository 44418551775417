"use client";

import { FC, useState } from "react";
import { Stack } from "@ui/layout";
import Image from "next/image";
import { Icon, Tag } from "@ui/elements";
import dayjs from "dayjs";
import { Tab } from "@components/Tab/Tab";
import { TipsWithFixture, useGetMatchAnalysis } from "@api";
import { MatchPreview } from "@components/FixtureCard/Tabs/MatchPreview";
import { FixtureProvider } from "@components/FixtureCard/context";
import { CorrectScore } from "@components/FixtureCard/Tabs/CorrectScore";
import { TeamComparison } from "@components/FixtureCard/Tabs/TeamComparison";
import { DoDont } from "@components/FixtureCard/Tabs/DoDont";
import TipTable from "@components/TipsTable/TipsTable";

type FixtureCardProps = {
  data: TipsWithFixture;
  onTabChange?: () => void;
};

export const FixtureCard: FC<FixtureCardProps> = ({
  data: { fixture, tips },
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState<string>("match-preview");

  const source = useGetMatchAnalysis(String(fixture.fixture.id));

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <FixtureProvider value={{ source, fixture }}>
      <article
        key={`recent-fixture-${fixture.fixture.id}`}
        className="flex w-full flex-col items-start gap-xl rounded-lg border border-gray-200 bg-white p-4 dark:border-neutral-700 dark:bg-neutral-900 dark:shadow-none"
      >
        <header className="flex w-full items-center justify-between gap-md">
          <div className="flex min-w-0 flex-1 items-center gap-xs overflow-hidden md:gap-md">
            <div className="flex">
              <figure className="flex size-14 items-center justify-center rounded-full border-4 border-white bg-gray-100 p-3 md:size-16 md:p-4 dark:border-neutral-900 dark:bg-neutral-800">
                <Image
                  alt={`Logo of ${fixture.teams.home.name}`}
                  width={48}
                  height={48}
                  src={fixture.teams.home.logo}
                  loading="lazy"
                />
                <figcaption className="sr-only">
                  Logo of {fixture.teams.home.name}
                </figcaption>
              </figure>
              <figure className="-ml-4 flex size-14 items-center justify-center rounded-full border-4 border-white bg-gray-100 p-3 md:size-16 md:p-4 dark:border-neutral-900 dark:bg-neutral-800">
                <Image
                  alt={`Logo of ${fixture.teams.away.name}`}
                  width={48}
                  height={48}
                  src={fixture.teams.away.logo}
                  loading="lazy"
                />
                <figcaption className="sr-only">
                  Logo of {fixture.teams.away.name}
                </figcaption>
              </figure>
            </div>

            <div className="min-w-0 flex-1 overflow-hidden">
              <p className="truncate font-semibold dark:text-neutral-300">
                {fixture.teams.home.name} vs {fixture.teams.away.name}
              </p>
              <Stack
                direction="horizontal"
                align="center"
                spacing="xs"
                className="min-w-0 overflow-hidden"
              >
                <div className="flex min-w-0 items-center gap-xxs text-gray-400">
                  <Icon icon="CalendarBlank" className="size-4" />
                  <time
                    dateTime={dayjs(fixture.fixture.date).format("YYYY-MM-DD")}
                    className="font-regular truncate text-xs text-gray-400"
                  >
                    {dayjs(fixture.fixture.date).format("DD MMMM")}
                  </time>
                </div>
                <div className="flex min-w-0 items-center gap-xxs text-gray-400">
                  <Icon icon="Trophy" className="size-4" />
                  <span className="font-regular truncate text-xs">
                    {fixture.league.name}
                  </span>
                </div>
              </Stack>
            </div>
          </div>

          <div className="flex-shrink-0">
            <Tag variant="neutral" className="text-nowrap">
              {fixture.score.fulltime.home} - {fixture.score.fulltime.away}
            </Tag>
          </div>
        </header>

        <Tab
          value={activeTab}
          onChange={handleTabChange}
          onContentUpdate={() => {
            onTabChange?.();
          }}
          tabId={`fixture-card-${fixture.fixture.id}`}
          items={[
            {
              name: "match-preview",
              label: "Match Preview",
              icon: "Newspaper",
              content: <MatchPreview />,
            },
            {
              name: "correct-score",
              label: "Correct Score",
              icon: "Target",
              content: <CorrectScore />,
            },
            {
              name: "team-comparison",
              label: "Team Comparison",
              icon: "ArrowsLeftRight",
              content: <TeamComparison />,
            },
            {
              name: "do-dont",
              label: "Do’s & Don’ts",
              icon: "ThumbsUp",
              content: <DoDont />,
            },
            {
              name: "tips",
              label: "Betting Tips",
              icon: "Coins",
              highlighted: true,
              content: <TipTable data={tips} />,
            },
          ]}
        />
      </article>
    </FixtureProvider>
  );
};
