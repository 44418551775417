import { Tag, Title } from "@ui/elements";
import React, { FC, HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

type GetInTouchHeaderProps = HTMLAttributes<HTMLDivElement> & {
  hasId?: boolean;
};

export const GetInTouchHeader: FC<GetInTouchHeaderProps> = ({
  className,
  hasId,
  ...rest
}) => {
  return (
    <header
      className={cn("flex w-full flex-col items-start gap-md", className)}
      {...rest}
    >
      <Tag size="md" variant="neutral">
        Contact us
      </Tag>
      <Title
        as="h2"
        styleAs="h1"
        className="max-w-2xl"
        {...(hasId ? { id: "contact-heading" } : {})}
      >
        <span className="sr-only">
          Get in Touch with us! We&#39;d love to hear from you!
        </span>
        Get in Touch!
      </Title>
      <Title as="h3" type="subtitle" color="tertiary" className="max-w-md">
        Have a question or just want to say hi? We’d love to hear from you!
      </Title>
    </header>
  );
};
