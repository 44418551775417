"use client";
import { Stack } from "@ui/layout";
import { Button, Icon, Title } from "@ui/elements";
import Image from "next/image";

import telegram from "./telegram.png";
import { useDeviceType } from "@hooks/useDeviceType";
import { Container } from "@components/Container/Container";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams } from "@utils";

export const CommunityChannel = () => {
  const deviceType = useDeviceType();

  return (
    <section
      className="relative flex flex-col gap-md overflow-hidden px-6 py-10"
      aria-labelledby="community-channel-heading"
    >
      {/* Background with blurred bubbles */}
      <div className="absolute inset-0">
        <div className="relative h-full w-full bg-[#1c8adb1a]">
          <div className="absolute left-1/4 top-10 h-40 w-40 rounded-full bg-[#0088cc] opacity-50 blur-3xl"></div>
          <div className="absolute left-3/4 top-1/2 h-40 w-40 rounded-full bg-[#005f99] opacity-50 blur-2xl"></div>
          <div className="absolute bottom-10 right-1/4 h-32 w-32 rounded-full bg-[#007bb5] opacity-40 blur-3xl"></div>
        </div>
      </div>

      <Container>
        <div className="relative mx-auto flex max-w-7xl flex-col items-center justify-between gap-md md:flex-row md:gap-xxxl">
          {/* Left content with AOS animation */}
          <Stack
            direction="horizontal"
            spacing="xl"
            align="center"
            data-aos={deviceType === "desktop" ? "fade-right" : "fade-up"}
            className="w-full items-start text-center md:w-auto md:text-left lg:items-center"
          >
            <Image
              src={telegram}
              alt="Chat"
              className="size-20 md:size-24"
              loading="lazy"
            />
            <Stack spacing="md" className="w-full justify-center">
              <div className="flex flex-col gap-sm">
                <Title as="h2" styleAs="h2" id="community-channel-heading">
                  Join our Telegram Community
                </Title>
                <Title
                  as="h3"
                  styleAs="h5"
                  type="subtitle"
                  color="tertiary"
                  className="max-w-3xl font-light leading-relaxed tracking-wide text-gray-700 dark:text-neutral-200"
                >
                  Connect with fellow bettors on Telegram and receive exclusive
                  match predictions, real-time updates, and expert betting
                  strategies every week!
                </Title>
              </div>
            </Stack>
          </Stack>

          <Stack
            data-aos={deviceType === "desktop" ? "fade-left" : "fade-up"}
            className="mt-6 w-full md:mt-0 md:w-auto"
          >
            <Button
              size="large"
              className="w-full bg-transparent pr-6 text-[#0088cc] shadow-sm ring-1 ring-[#0088cc] hover:bg-[#0088cc] hover:text-white hover:ring-[#007bb5] active:bg-[#005f99] active:text-white md:w-auto"
              tabIndex={0}
              aria-label="Join Football Genie AI's Telegram Community"
              as="link"
              href="https://t.me/+vMSyhj7q38xkZDRk"
              target="_blank"
              onClick={() =>
                sendGTMEvent({
                  event: "link_click",
                  value: "telegram_community",
                  ...getUtmParams(),
                })
              }
            >
              <Stack
                direction="horizontal"
                align="center"
                className="justify-center"
              >
                <Icon
                  icon="TelegramLogo"
                  className="-ml-4 size-6"
                  weight="duotone"
                />
                <span>Join Telegram Now</span>
              </Stack>
            </Button>
          </Stack>
        </div>
      </Container>
    </section>
  );
};
