import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";

type ContainerProps = PropsWithChildren<{
  isFluid?: boolean;
}>;

export const Container: FC<ContainerProps> = ({ children, isFluid }) => {
  return (
    <div className={classNames(["container mx-auto", { "is-fluid": isFluid }])}>
      {children}
    </div>
  );
};
