import { Button, Icon, Tag, Title } from "@ui/elements";
import appScreenshot from "./app-screenshot.png";

import appScreenshotDark from "./app-screenshot@dark.png";
import decor from "./decor.png";
import bubbleOne from "./bubbles/1.png";
import bubbleOneDark from "./bubbles/1@dark.png";
import bubbleTwo from "./bubbles/2.png";
import bubbleTwoDark from "./bubbles/2@dark.png";
import bubbleThree from "./bubbles/3.png";
import bubbleThreeDark from "./bubbles/3@dark.png";
import bubbleFour from "./bubbles/4.png";
import bubbleFourDark from "./bubbles/4@dark.png";
import { sendGTMEvent } from "@next/third-parties/google";

import { Input } from "@components/forms/Input/Input";
import { Image } from "@components/Image/Image";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import { getUtmParams, useUtmLink } from "@utils";

type GetStartedFormValues = {
  email?: string;
};

export const Hero = () => {
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");
  const form = useForm<GetStartedFormValues>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (values: GetStartedFormValues) => {
    if (values.email) {
      const url = new URL(dashboardLink, window.location.origin);
      url.searchParams.set("email", values.email);
      window.location.href = url.toString();
      return;
    }

    window.location.href = dashboardLink;
  };

  return (
    <section
      aria-labelledby="hero-heading"
      className="bg-white px-3 dark:bg-neutral-900"
    >
      <div className="container mx-auto">
        <div className="flex flex-col items-center gap-xxl">
          <header className="flex flex-col items-center gap-md">
            <Tag variant="neutral" as="p">
              Powered by ChatGPT
            </Tag>
            <Title
              align="center"
              as="h1"
              id="hero-heading"
              color="secondary"
              className="relative max-w-4xl text-4xl leading-tight tracking-wide md:text-5xl lg:text-6xl dark:text-gray-200"
            >
              <strong className="text-primary">Win More</strong> with AI-Powered
              Football Tips
              <Image
                className="absolute -right-7 -top-7 hidden w-12 md:block"
                src={decor}
                alt=""
                aria-hidden="true"
                data-aos="fade-down-left"
                data-aos-delay="250"
              />
            </Title>
            <Title
              as="h2"
              styleAs="h3"
              align="center"
              className="max-w-4xl text-lg font-light leading-snug tracking-wide md:text-xl lg:text-2xl dark:text-gray-400"
              color="tertiary"
            >
              Leverage advanced AI analysis, betting strategies, and expert
              insights to outsmart the odds and boost your betting profits.
            </Title>
          </header>

          <div className="relative z-10 flex w-full flex-col gap-xxl">
            <form
              className="mx-auto flex w-full max-w-md gap-md"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={form.control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    label="E-mail"
                    className="grow"
                    autoComplete="email"
                    id="hero-email"
                    type="email"
                    {...field}
                  />
                )}
              />

              <Button
                color="primary"
                size="medium"
                className="py-3.5"
                type="submit"
                aria-label="Get started with free AI football tips"
                onClick={() =>
                  sendGTMEvent({
                    event: "cta_click",
                    value: "hero_try_for_free",
                    ...getUtmParams(),
                  })
                }
              >
                Try for Free
              </Button>
            </form>

            <ul
              className="flex w-full flex-wrap items-center justify-center gap-md gap-y-3"
              aria-label="Key Highlights"
            >
              <li className="flex items-center gap-xs bg-white py-0.5 dark:bg-neutral-900">
                <Icon icon="SealCheck" className="size-5 text-green-500" />
                <p className="text-nowrap text-sm text-gray-500 dark:text-neutral-400">
                  Free Plan Available
                </p>
              </li>
              <li className="flex items-center gap-xs bg-white py-0.5 dark:bg-neutral-900">
                <Icon icon="SealCheck" className="size-5 text-green-500" />
                <p className="text-nowrap text-sm text-gray-500 dark:text-neutral-400">
                  200+ Active Users and Growing
                </p>
              </li>
              <li className="flex items-center gap-xs bg-white py-0.5 dark:bg-neutral-900">
                <Icon icon="SealCheck" className="size-5 text-green-500" />
                <p className="text-nowrap text-sm text-gray-500 dark:text-neutral-400">
                  Secure &amp; Trusted Payments
                </p>
              </li>
            </ul>
          </div>

          <div className="relative -mb-[30%] mt-16 aspect-[1400/879] w-full max-w-[90rem] md:mt-24 lg:-mb-96 lg:aspect-[1400/879]">
            <Image
              src={bubbleOne}
              darkSrc={bubbleOneDark}
              className="absolute -top-[135px] w-[150px] md:bottom-[80%] md:left-[3%] md:top-auto md:w-[23%] lg:bottom-[75%]"
              alt=""
              aria-hidden="true"
              data-aos="fade-up"
              data-aos-delay="50"
            />
            <Image
              src={bubbleTwo}
              darkSrc={bubbleTwoDark}
              className="absolute -top-[75px] left-[10px] w-[30px] md:bottom-[97%] md:left-[2%] md:top-auto md:w-[6%]"
              alt=""
              aria-hidden="true"
              data-aos="fade-up"
            />
            <Image
              src={bubbleThree}
              darkSrc={bubbleThreeDark}
              className="absolute -top-[75px] right-[55px] w-[110px] md:bottom-[95%] md:right-[16%] md:top-auto md:w-[18%]"
              alt=""
              aria-hidden="true"
              data-aos="fade-up"
              data-aos-delay="150"
            />
            <Image
              src={bubbleFour}
              darkSrc={bubbleFourDark}
              className="absolute -top-[130px] right-[10px] w-[70px] md:bottom-[90%] md:right-[1%] md:top-auto md:w-[11%]"
              alt=""
              aria-hidden="true"
              data-aos="fade-up"
            />

            {/* Main App Screenshot Image */}
            <Image
              className="relative z-10 h-full w-full rounded-lg border border-gray-200 object-cover md:rounded-xl lg:rounded-2xl dark:border-neutral-700"
              src={appScreenshot}
              darkSrc={appScreenshotDark}
              fill
              priority
              quality={100}
              placeholder="blur"
              alt="Screenshot of the AI-driven dashboard, showcasing AI-driven betting predictions and results."
            />
          </div>
        </div>
      </div>
    </section>
  );
};
