"use client";

import AOS from "aos";
import { useEffect } from "react";
import { useReducedMotion } from "framer-motion";

const AOSWrapper = () => {
  const prefersReducedMotion = useReducedMotion();

  useEffect(() => {
    if (!prefersReducedMotion) {
      AOS.init();
    }
  }, [prefersReducedMotion]);

  return null;
};

export default AOSWrapper;
