import React, { forwardRef, TextareaHTMLAttributes } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { cn } from "@/lib/utils";

type TextAreaProps = {
  label: string;
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "style">;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, id, className, value, ...rest }, ref) => {
    return (
      <div className="relative w-full">
        <TextareaAutosize
          id={id}
          value={value}
          className={cn(
            "peer block w-full resize-none overflow-hidden rounded-lg border-gray-200 bg-gray-100 p-4 text-sm placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-primary focus:pb-2 focus:pt-6 focus:ring-primary disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:ring-neutral-600 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-7",
            className,
          )}
          placeholder="********"
          ref={ref}
          {...rest}
        />

        <label
          htmlFor={id}
          className="pointer-events-none absolute start-0 top-0 h-full origin-[0_0] truncate border border-transparent p-4 text-sm transition duration-100 ease-in-out peer-focus:-translate-y-1.5 peer-focus:translate-x-0.5 peer-focus:scale-90 peer-focus:text-gray-400 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-400 dark:text-neutral-400 dark:peer-focus:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-gray-400"
        >
          {label}
        </label>
      </div>
    );
  },
);

TextArea.displayName = "TextArea";
