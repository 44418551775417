import React from "react";
import { cn } from "@/lib/utils";

type HeadingTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /** The heading level to use (default is 'h1') */
  as?: HeadingTags;
  /** The heading style to use (default is 'h1') */
  styleAs?: HeadingTags;
  /** The content of the title */
  children: React.ReactNode;
  /** Additional CSS classes */
  className?: string;
  /** Text alignment */
  align?: "left" | "center" | "right";
  /** Color variant */
  color?: "primary" | "secondary" | "tertiary" | "light";
  type?: "title" | "subtitle";
}

export const Title: React.FC<TitleProps> = ({
  as = "h1",
  styleAs,
  children,
  className = "",
  align = "left",
  color = "primary",
  type = "title",
  ...props
}) => {
  const HeadingTag = as;

  const baseStyles = "font-bold";

  const sizeStyles: Record<HeadingTags, string> = {
    h1: "text-4xl md:text-5xl md:leading-tight",
    h2: "text-2xl md:text-3xl",
    h3: "text-xl md:text-2xl",
    h4: "text-lg md:text-xl",
    h5: "text-md md:text-lg",
    h6: "text-base",
  };

  const alignmentStyles: Record<string, string> = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  };

  const colorStyles: Record<string, string> = {
    primary: "text-gray-900 dark:text-neutral-200",
    secondary: "text-gray-700",
    tertiary: "text-gray-500 dark:text-neutral-400",
    light: "text-gray-300 dark:text-neutral-700",
  };

  const weight: Record<string, string> = {
    title: "font-bold",
    subtitle: "font-light leading-relaxed",
  };

  return (
    <HeadingTag
      className={cn(
        "tracking-wide",
        baseStyles,
        sizeStyles[styleAs || as],
        alignmentStyles[align],
        colorStyles[color],
        weight[type],
        className,
      )}
      {...props}
    >
      {children}
    </HeadingTag>
  );
};
