"use client";

import React, { useEffect, useState } from "react";
import { Button, Icon } from "@ui/elements";
import { cn } from "@/lib/utils";

export const BackToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.scrollY > 500) setIsVisible(true);
    else setIsVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Button
      onClick={scrollToTop}
      size="custom"
      className={cn(
        "fixed bottom-3 right-3 z-20 size-14 rounded-full border border-gray-200 bg-white shadow-sm hover:bg-gray-100 active:bg-gray-200 md:bottom-6 md:right-6 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-200 dark:shadow-none dark:hover:bg-neutral-700 dark:active:bg-neutral-600",
        {
          "pointer-events-none translate-y-full opacity-0": !isVisible,
        },
      )}
      aria-label="Scroll to top"
    >
      <Icon icon="CaretUp" className="size-6" />
    </Button>
  );
};
