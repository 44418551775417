const StatusColor = {
  Success: "success",
  Warning: "warning",
  Info: "info",
  Neutral: "neutral",
  Danger: "danger",
} as const;
export type StatusColor = (typeof StatusColor)[keyof typeof StatusColor];

type StatusColorClasses = {
  bg: string;
  text: string;
  mid: string;
};

export const statusColorClasses: Record<StatusColor, StatusColorClasses> = {
  success: {
    bg: "bg-green-500/20",
    mid: "bg-green-500/60",
    text: "text-green-600",
  },
  warning: {
    bg: "bg-yellow-500/20",
    mid: "bg-yellow-500/60",
    text: "text-yellow-600",
  },
  info: {
    bg: "bg-blue-500/20",
    mid: "bg-blue-500/60",
    text: "text-blue-600",
  },
  neutral: {
    bg: "bg-neutral-100 dark:bg-neutral-600",
    mid: "bg-neutral-500/60",
    text: "text-neutral-600",
  },
  danger: {
    bg: "bg-red-500/20",
    mid: "bg-red-500/60",
    text: "text-red-600",
  },
};
