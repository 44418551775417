import { Stack } from "@ui/layout";
import { ReadmeContent } from "@components/ReadmeContent/ReadmeContent";
import { useFixtureContext } from "@components/FixtureCard/context";
import { InfoCard } from "@components/IconCard/IconCard";
import { ProgressBar } from "@components/ProgressBar/ProgressBar";
import { StatusColor } from "@utils";

function getStatusByNumber(index = 0): StatusColor {
  if (index <= 5) {
    return "danger";
  } else if (index <= 7) {
    return "warning";
  }
  return "success";
}

function getStatusByNumberReverse(index = 0): StatusColor {
  if (index <= 3) {
    return "success";
  } else if (index <= 6) {
    return "warning";
  }
  return "danger";
}

export const TeamComparison = () => {
  const {
    source: { data },
    fixture,
  } = useFixtureContext();

  if (!data?.data.team_comparison) {
    return null;
  }

  return (
    <Stack spacing="xl">
      <h4 className="sr-only">Team Comparison</h4>
      <Stack
        direction="horizontal"
        align="center"
        spacing="xs"
        className="w-full"
        role="group"
      >
        <InfoCard
          image={fixture.teams.home.logo}
          imageAlt={`${fixture.teams.home.name} Logo`}
          value={data?.data.team_comparison.home_team.team_health_index}
          valueSubtext="/10"
          label={fixture.teams.home.name}
          textColor={getStatusByNumber(
            data?.data.team_comparison.home_team.team_health_index,
          )}
          aria-label={`${fixture.teams.home.name} Team Health Index`}
          className="w-1/2 flex-[50%]"
        />
        <InfoCard
          image={fixture.teams.away.logo}
          imageAlt={`${fixture.teams.away.name} Logo`}
          value={data?.data.team_comparison.away_team.team_health_index}
          valueSubtext="/10"
          label={fixture.teams.away.name}
          textColor={getStatusByNumber(
            data?.data.team_comparison.away_team.team_health_index,
          )}
          aria-label={`${fixture.teams.away.name} Team Health Index`}
          className="w-1/2 flex-[50%]"
        />
      </Stack>

      <Stack className="w-full">
        <ProgressBar
          values={[
            data?.data.team_comparison.home_team.motivations,
            data?.data.team_comparison.away_team.motivations,
          ]}
          label="Motivations"
          leftLabel={`${fixture.teams.home.name} motivation score`}
          rightLabel={`${fixture.teams.away.name} motivation score`}
          prefix="/10"
          colorRule={getStatusByNumber}
          aria-label="Motivations Comparison"
        />
        <ProgressBar
          values={[
            data?.data.team_comparison.home_team.injury_impact,
            data?.data.team_comparison.away_team.injury_impact,
          ]}
          label="Injury Impact"
          leftLabel={`${fixture.teams.home.name} injury impact score`}
          rightLabel={`${fixture.teams.away.name} injury impact score`}
          prefix="/10"
          colorRule={getStatusByNumberReverse}
          aria-label="Injury Impact Comparison"
        />
        <ProgressBar
          values={[
            data?.data.team_comparison.home_team.tactical_advantage,
            data?.data.team_comparison.away_team.tactical_advantage,
          ]}
          label="Tactical Advantage"
          leftLabel={`${fixture.teams.home.name} tactical advantage score`}
          rightLabel={`${fixture.teams.away.name} tactical advantage score`}
          prefix="/10"
          colorRule={getStatusByNumber}
          aria-label="Tactical Advantage Comparison"
        />
        <ProgressBar
          values={[
            data?.data.team_comparison.home_team.recent_form,
            data?.data.team_comparison.away_team.recent_form,
          ]}
          leftLabel={`${fixture.teams.home.name} recent form score`}
          rightLabel={`${fixture.teams.away.name} recent form score`}
          label="Recent Form"
          prefix="/10"
          colorRule={getStatusByNumber}
          aria-label="Recent Form Comparison"
        />
      </Stack>

      <ReadmeContent
        className="prose prose-sm prose-slate dark:prose-invert"
        aria-label="Team Comparison Reasoning"
        content={data?.data.team_comparison.reasoning}
      />
    </Stack>
  );
};
