"use client";

import { Stack } from "@ui/layout";
import { Tag, Title, Icon, Button } from "@ui/elements";
import featureOne from "./feature-1.png";
import featureOneDark from "./feature-1@dark.png";
import featureTwo from "./feature-2.png";
import featureTwoDark from "./feature-2@dark.png";
import featureThree from "./feature-3.png";
import featureThreeDark from "./feature-3@dark.png";
import { StaticImageData } from "next/image";
import { Image } from "@components/Image/Image";

import { FC, PropsWithChildren, ReactElement, useRef } from "react";
import { cn } from "@/lib/utils";
import {
  motion,
  useTransform,
  useScroll,
  MotionValue,
  useSpring,
  useReducedMotion,
} from "framer-motion";
import { Container } from "@components/Container/Container";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

type FeatureTagProps = PropsWithChildren;

const FeatureTag: FC<FeatureTagProps> = ({ children }) => {
  return (
    <li className="flex items-center gap-xs text-sm md:text-base dark:text-neutral-300">
      <Icon
        icon="CheckCircle"
        size="1.3rem"
        className="text-green-700"
        aria-hidden="true"
      />
      <span>{children}</span>
    </li>
  );
};

function useParallax(
  scrollYProgress: MotionValue<number>,
  distance: number,
  stiffness = 100,
  damping = 20,
) {
  const y = useTransform(scrollYProgress, [0, 1], [-distance, distance]);
  return useSpring(y, { stiffness, damping });
}

type FeatureItemProps = {
  tagText: string;
  title: string;
  subtitle: string;
  tags: ReactElement[] | ReactElement;
  buttonText: string;
  buttonAriaLabel: string;
  isReverse?: boolean;
  image: StaticImageData;
  imageDark?: StaticImageData;
  imageAlt: string;
  buttonLink: string;
};

const FeatureItem: FC<FeatureItemProps> = ({
  title,
  subtitle,
  tags,
  tagText,
  buttonText,
  isReverse,
  image,
  imageDark,
  imageAlt,
  buttonAriaLabel,
  buttonLink,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const utmLink = useUtmLink(buttonLink);

  const featuerLink = buttonLink.startsWith("http") ? utmLink : buttonLink;

  const yLayer1 = useParallax(scrollYProgress, -50);
  const shouldReduceMotion = useReducedMotion();

  const opacityTransform = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

  return (
    <article
      className={cn(
        "relative flex w-full flex-col items-center justify-start gap-10 md:flex-row lg:justify-center",
        {
          "lg:flex-row-reverse": isReverse,
        },
      )}
      data-aos="fade-up"
      aria-labelledby={`${title.replace(/\s+/g, "-").toLowerCase()}-heading`}
    >
      <Stack
        direction="horizontal"
        className={cn("flex flex-col md:flex-row", {
          "lg:flex-1 lg:justify-center": isReverse,
        })}
      >
        <Stack spacing="xxl" className="w-full md:w-114 lg:w-auto lg:max-w-xl">
          <header className="flex flex-col items-start gap-md">
            <Tag size="md" variant="neutral" as="p">
              {tagText}
            </Tag>
            <Title
              as="h2"
              id={`${title.replace(/\s+/g, "-").toLowerCase()}-heading`}
              styleAs="h1"
            >
              {title}
            </Title>
            <Title type="subtitle" as="h3" color="tertiary">
              {subtitle}
            </Title>
          </header>
          <ul className="grid w-full max-w-md grid-cols-[auto,auto] gap-y-3">
            {tags}
          </ul>

          <Button
            color="primary"
            size="medium"
            aria-label={buttonAriaLabel}
            as="link"
            href={featuerLink}
            onClick={() =>
              sendGTMEvent({
                event: "cta_click",
                value: `features_${tagText.toLowerCase().replace(/\s+/g, "_")}`,
                ...getUtmParams(),
              })
            }
          >
            {buttonText}
          </Button>
        </Stack>
      </Stack>
      <div
        className={cn("relative flex justify-center", {
          ["lg:flex-1"]: !isReverse,
        })}
      >
        <div className="relative -mx-2 md:mx-none md:min-w-[30rem]" ref={ref}>
          <Image
            src={image}
            darkSrc={imageDark}
            alt={imageAlt}
            loading="lazy"
            className="relative z-10 w-full rounded-xl border border-gray-200 md:max-w-md lg:w-128 xl:w-[50rem] xl:max-w-xl dark:border-neutral-700"
          />
          {!shouldReduceMotion && (
            <motion.div
              style={{
                y: yLayer1,
                opacity: opacityTransform,
              }}
              className={cn(
                "absolute -bottom-7 -left-7 h-full w-full rounded-xl bg-primary-500/20 dark:bg-primary-500/30",
                {
                  "lg:-bottom-9 lg:-left-9": isReverse,
                  "lg:-bottom-9 lg:-right-9": !isReverse,
                },
              )}
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </article>
  );
};

export const Features = () => {
  return (
    <section
      className="relative py-28 md:py-44"
      id="features"
      aria-labelledby="features-heading"
      role="region"
    >
      <h2 id="features-heading" className="sr-only">
        Features
      </h2>
      <Container>
        <Stack spacing="unset" className="gap-36 px-6 md:gap-48">
          <FeatureItem
            tagText="Bankroll Management"
            title="Maximize Your Profits with our Strategy"
            subtitle="Implement scientifically-backed betting strategies to enhance your sports betting profitability."
            imageAlt="Screenshot of the AI predictions and betting tips' results over time."
            tags={
              <>
                <FeatureTag>Kelly Criterion</FeatureTag>
                <FeatureTag>Bet Tracking</FeatureTag>
                <FeatureTag>Flat Betting</FeatureTag>
                <FeatureTag>Custom Strategies</FeatureTag>
                <FeatureTag>Personalized Interface</FeatureTag>
              </>
            }
            buttonLink={process.env.NEXT_PUBLIC_APP_URL || ""}
            buttonText="Try for Free"
            buttonAriaLabel="Get Started with Football Genie AI for Free"
            image={featureOne}
            imageDark={featureOneDark}
          />
          <FeatureItem
            isReverse
            tagText="Contextual Analysis"
            title="Football Beyond Stats"
            subtitle="We know football is not played by numbers. Genie considers all contextual factors to tip the odds in your favor."
            imageAlt="Screenshot of the AI Croatia and Portugal match analysis showcasing the accuracy of Football Genie AI."
            tags={
              <>
                <FeatureTag>Motivations</FeatureTag>
                <FeatureTag>Team Records</FeatureTag>
                <FeatureTag>Style of Play</FeatureTag>
                <FeatureTag>Strengths & Weaknesses</FeatureTag>
                <FeatureTag>Sentiment Analysis</FeatureTag>
                <FeatureTag>Injury Impact</FeatureTag>
                <FeatureTag>Managerial Changes</FeatureTag>
              </>
            }
            buttonText="Latest Predictions"
            buttonLink="#latest-predictions"
            buttonAriaLabel="See the Latest Football Predictions made by Football Genie AI"
            image={featureTwo}
            imageDark={featureTwoDark}
          />
          <FeatureItem
            tagText="Personalized Insights"
            title="Tailored to Your Betting Style"
            subtitle="Experience a personalized platform designed around your preferences and betting strategy."
            imageAlt="Screenshot of the Football Genie AI's strategy and bankroll management screen."
            tags={
              <>
                <FeatureTag>+80% Hit-Rate</FeatureTag>
                <FeatureTag>High Odds</FeatureTag>
                <FeatureTag>Europe Top 5</FeatureTag>
                <FeatureTag>Correct Score Prediction</FeatureTag>
                <FeatureTag>Goals Over/Under</FeatureTag>
                <FeatureTag>Match Outcome</FeatureTag>
              </>
            }
            buttonLink={process.env.NEXT_PUBLIC_APP_URL || ""}
            buttonText="Get Started"
            buttonAriaLabel="Get Started with Football Genie AI"
            image={featureThree}
            imageDark={featureThreeDark}
          />
        </Stack>
      </Container>
    </section>
  );
};
