"use client";

import { Hero } from "@components/Hero/Hero";
import { HowItWorks } from "@components/HowItWorks/HowItWorks";
import { Features } from "@components/Features/Features";
import { MatchAnalysis } from "@components/MatchAnalysis/MatchAnalysis";
import { SupportedLeagues } from "@components/SupportedLeagues/SupportedLeagues";
import { TrustBooster } from "@components/TrustBooster/TrustBooster";
import { Testimonials } from "@components/Testimonials/Testimonials";
import { CommunityChannel } from "@components/CommunityChannel/CommunityChannel";
import { PricingTable } from "@components/Pricing/Pricing";
import { RecentFixtures } from "@components/RecentFixtures";
import { AboutUs } from "@components/AboutUs/AboutUs";
import { Contact } from "@components/Contact/Contact";
import { FAQ } from "@components/FAQ/FAQ";

function Home() {
  return (
    <>
      <Hero />
      <HowItWorks />
      <TrustBooster />
      <Features />
      <SupportedLeagues />
      <MatchAnalysis />
      <Testimonials />
      <CommunityChannel />
      <RecentFixtures />
      <AboutUs />
      <FAQ />
      <PricingTable />
      <Contact />
    </>
  );
}

export default Home;
