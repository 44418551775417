import { Stack } from "@ui/layout";
import { Button, Tag, Title } from "@ui/elements";
import { Icon, Icons } from "@ui/elements/Icon/Icon";
import React, { FC, HTMLAttributes, PropsWithChildren } from "react";
import { GlowCapture } from "@/components";
import { cn } from "@/lib/utils";
import DotPattern from "@components/ui/dot-pattern";
import { Container } from "@components/Container/Container";

type StepBoxProps = PropsWithChildren<{
  icon?: Icons;
  iconLabel?: string;
  title: string;
  step: string;
  last?: boolean;
}> &
  HTMLAttributes<HTMLLIElement>;

const StepBox: FC<StepBoxProps> = ({
  icon,
  title,
  children,
  step,
  last,
  className,
  iconLabel,
  ...rest
}) => {
  return (
    <li
      className={cn("relative flex flex-col gap-md text-white", className)}
      {...rest}
    >
      <Stack
        direction="horizontal"
        align="start"
        className="w-full justify-between"
      >
        {icon && (
          <Icon
            icon={icon}
            aria-label={iconLabel}
            className="size-12 glow:text-glow md:size-16"
          />
        )}
        <Tag
          size="md"
          as="p"
          className="border-1 border-white/10 bg-white/10 glow:border-glow glow:bg-white/50 glow:ring-1 glow:ring-glow"
        >
          {step}
        </Tag>
      </Stack>
      <Stack spacing="sm">
        <Title as="h3" styleAs="h4" className="tracking-wider text-white">
          {title}
        </Title>
        <p className="text-sm leading-relaxed text-gray-200 glow:text-glow/[.15] md:text-base">
          {children}
        </p>
      </Stack>
      {!last && (
        <Button
          isRounded
          className="pointer-events-none absolute -right-[4.5rem] top-1/2 hidden size-14 -translate-y-1/2 bg-white/10 text-white glow:bg-primary/10 lg:flex"
          aria-hidden="true"
          tabIndex={-1}
        >
          <Icon icon="ArrowRight" className="size-6" aria-hidden="true" />
        </Button>
      )}
    </li>
  );
};

export const HowItWorks = () => {
  return (
    <section
      className="relative overflow-hidden bg-primary-900 px-6 pb-24 pt-[35%] sm:pt-52 md:pt-72 lg:pt-114 dark:bg-primary-900/60"
      id="how-it-works"
      aria-labelledby="how-it-works-heading"
    >
      <div className="absolute left-0 right-0 top-0 mx-auto h-full w-full max-w-2xl">
        <DotPattern
          width={16}
          height={16}
          cr={2}
          cy={2}
          cx={2}
          className={cn(
            "[mask-image:radial-gradient(300px_circle_at_center,white,transparent)]",
            "fill-gray-400/40",
          )}
        />
      </div>
      <Container>
        <Stack className="relative" align="center" spacing="xxl">
          <header className="flex flex-col items-center gap-md">
            <Tag size="md" className="bg-gray-200 dark:bg-neutral-800">
              Learn Our Process
            </Tag>
            <Title
              as="h2"
              styleAs="h1"
              id="how-it-works-heading"
              className="w-full text-white dark:text-gray-100"
              align="center"
            >
              How it Works?
            </Title>
            <Title
              as="h3"
              type="subtitle"
              color="light"
              className="max-w-4xl text-center text-lg font-light leading-relaxed tracking-wide md:text-2xl dark:text-neutral-300"
            >
              We use an effective process to deliver accurate football betting
              tips and match analysis. By combining historical data, with
              contextual factors, we help you make more profitable betting
              decisions.
            </Title>
          </header>
          <GlowCapture glowColor="white">
            <ol className="glow grid grid-cols-1 gap-10 rounded-lg border-4 border-white/5 bg-white/10 p-7 glow:border-glow glow:bg-glow/[.15] glow:ring-1 glow:ring-glow md:grid-cols-2 md:p-10 lg:grid-cols-3 lg:gap-24">
              <StepBox
                step="Step 1"
                title="Statistical Prediction"
                icon="Database"
                iconLabel="Database icon"
                data-aos="fade-in"
              >
                Our system processes over <strong>10 years</strong> of football
                data to uncover betting patterns and trends, focusing on metrics
                like goals, possession, and shots. This data-driven foundation
                enhances the accuracy of our football betting predictions.
              </StepBox>
              <StepBox
                step="Step 2"
                title="Recent Form Analysis"
                icon="Fire"
                iconLabel="Fire icon"
                data-aos="fade-in"
                data-aos-delay="100"
              >
                We assess current team and player form, analyzing recent matches
                to determine momentum and performance trends. This ensures our
                predictions reflect the latest dynamics in the football
                landscape.
              </StepBox>
              <StepBox
                step="Step 3"
                title="Contextual Insights"
                icon="Newspaper"
                iconLabel="Newspaper icon"
                last
                data-aos="fade-in"
                data-aos-delay="200"
                className="md:col-span-2 lg:col-span-1"
              >
                We refine our predictions by considering non-statistical
                factors, such as injuries, suspensions, and team morale. By
                blending quantitative and qualitative insights, we provide
                highly accurate betting tips.
              </StepBox>
            </ol>
          </GlowCapture>
        </Stack>
      </Container>
    </section>
  );
};
