import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
} from "react";
import { Colors, Variants } from "@ui/types";
import { cn } from "@/lib/utils";

type ButtonSizes = "custom" | "small" | "normal" | "medium" | "large";

type ButtonCommonProps = {
  color?: Colors;
  variant?: Variants;
  size?: ButtonSizes;
  isRounded?: boolean;
  isLoading?: boolean;
};

type ButtonAsButtonProps = {
  as?: "button";
} & ButtonCommonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonAsLinkProps = {
  as?: "link";
} & ButtonCommonProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = ButtonAsButtonProps | ButtonAsLinkProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = "button",
      children,
      color,
      size = "normal",
      className,
      isRounded,
      isLoading,
      ...rest
    },
    ref,
  ) => {
    const buttonClasses: Record<Colors, string> = {
      primary:
        "bg-primary dark:text-gray-900 text-white font-semibold hover:bg-primary-dark active:bg-primary-darker",
      secondary: "bg-secondary hover:bg-secondary:100",
      success: "bg-green",
      warning: "bg-orange",
      info: "bg-blue",
      link: "bg-blue",
      danger: "bg-red",
      neutral:
        "bg-white border-gray-200 hover:bg-gray-50 active:bg-gray-100 text-gray-600 ring-1 ring-gray-200 dark:bg-neutral-800 dark:text-neutral-300 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:active:bg-neutral-600 dark:ring-neutral-700 dark:hover:bg-neutral-700 dark:active:bg-neutral-600",
    };

    const buttonSizeClasses: Record<ButtonSizes, string> = {
      custom: "",
      small: "px-3 py-1",
      normal: "px-4 py-2",
      medium: "px-6 py-3",
      large: "px-9 py-4 text-md font-semibold",
    };

    const radiusClassName = isRounded ? "rounded-full" : "rounded-md";

    const buttonClassName = cn(
      "inline-flex items-center justify-center truncate text-base font-medium leading-6 whitespace-no-wrap focus:outline-none transition-all tracking-wide focus:shadow-sm active:scale-95 min-w-fit after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:-mt-2.5 after:-ml-2.5 after:size-5 after:border-2 after:border-gray-200 after:border-t-transparent after:rounded-full after:animate-spin after:opacity-0 after:transition-all",
      color && buttonClasses[color],
      buttonSizeClasses[size],
      radiusClassName,
      className,
      isLoading &&
        "text-transparent dark:text-transparent pointer-events-none relative after:opacity-100",
    );

    return as === "link" ? (
      <a className={buttonClassName} {...(rest as ButtonAsLinkProps)}>
        {children}
      </a>
    ) : (
      <button
        ref={ref}
        className={buttonClassName}
        disabled={isLoading}
        {...(rest as ButtonAsButtonProps)}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";
