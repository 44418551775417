import React from "react";
import { Icon, Icons } from "@ui/elements";
import Image from "next/image";
import classNames from "classnames";
import { statusColorClasses } from "@utils";
import CountUp from "react-countup";

type InfoIconCardCommonProps = {
  value?: string | number;
  valueSubtext?: string;
  label: string;
  color?: "success" | "warning" | "info" | "neutral" | "danger";
  textColor?: "success" | "warning" | "info" | "neutral" | "danger";
  className?: string;
  ["aria-label"]?: string;
  isReverse?: boolean;
};

type InfoIconCardProps = {
  icon: Icons;
  iconAlt: string;
} & InfoIconCardCommonProps;

type InfoImageCardProps = {
  image: string;
  imageAlt: string;
} & InfoIconCardCommonProps;

type InfoCardProps = InfoIconCardProps | InfoImageCardProps;

export const InfoCard: React.FC<InfoCardProps> = ({
  value,
  valueSubtext,
  label,
  color = "neutral",
  textColor = "neutral",
  className,
  isReverse,
  ...props
}) => {
  const colorClass = statusColorClasses[color];
  const textColorClass = statusColorClasses[textColor];

  return (
    <div
      className={classNames(
        "flex flex-1 items-center gap-xs rounded-lg border border-gray-200 bg-white p-3 dark:border-neutral-700 dark:bg-neutral-700",
        className,
        { "flex-row-reverse": isReverse },
      )}
      aria-label={props["aria-label"]}
    >
      <div
        className={classNames(
          "flex size-12 min-w-12 items-center justify-center rounded-md",
          colorClass.bg,
          colorClass.text,
        )}
      >
        {"icon" in props && (
          <Icon
            icon={props.icon}
            className="size-6"
            weight="regular"
            {...("iconAlt" in props && { "aria-label": props.iconAlt })}
          />
        )}
        {"image" in props && (
          <figure>
            <Image
              className="size-6 object-contain"
              width={48}
              height={48}
              src={props.image}
              alt={props.imageAlt}
            />
            <figcaption className="sr-only">{label}</figcaption>
          </figure>
        )}
      </div>
      <div className="w-full min-w-0">
        <div
          className={classNames("flex items-baseline space-x-0.5", {
            "justify-end": isReverse,
          })}
        >
          <p
            className={classNames(
              "truncate font-semibold dark:text-gray-100",
              textColorClass,
            )}
          >
            {typeof value === "number" ? (
              <CountUp end={value} duration={1.5} />
            ) : (
              value
            )}
          </p>
          {valueSubtext && (
            <p className="truncate text-xs text-gray-500 dark:text-neutral-200">
              {valueSubtext}
            </p>
          )}
        </div>
        <p
          className={classNames(
            "truncate text-xs text-gray-500 dark:text-neutral-300",
            {
              "text-right": isReverse,
            },
          )}
        >
          {label}
        </p>
      </div>
    </div>
  );
};
