import { FAQItem } from "@components/FAQ/FAQ";
import React from "react";

export const faqItems: FAQItem[] = [
  {
    question: "When is it the best time for betting?",
    type: "general",
    answer: `The best time for betting depends on the leagues and their predictability periods:

European Leagues:
The most consistent period is mid-season (December to February) when teams are in form and lineups stabilize. However, avoid betting immediately after international breaks or during transfer windows when results can be unpredictable.
Look for high-confidence bets, particularly in matches involving top-performing teams.

American & South American Leagues:
For the MLS and Copa Libertadores, the period from June to August often sees steady performance as teams are in mid-season. Keep an eye on knockout stages where strategies can shift drastically.
Back in-form teams with depth, especially in the MLS.`,
    content: (
      <>
        <p>
          The best time for betting depends on the leagues and their
          predictability periods:
        </p>
        <ul>
          <li>
            <strong>European Leagues:</strong>
            <p>
              The most consistent period is mid-season{" "}
              <strong>(December to February)</strong> when teams are in form and
              lineups stabilize. However, avoid betting immediately after
              international breaks or during transfer windows when results can
              be unpredictable.
            </p>
            <blockquote>
              Look for high-confidence bets, particularly in matches involving
              top-performing teams.
            </blockquote>
          </li>
          <li>
            <strong>American & South American Leagues:</strong>
            <p>
              For the MLS and Copa Libertadores, the period from{" "}
              <strong>June to August</strong> often sees steady performance as
              teams are in mid-season. Keep an eye on knockout stages where
              strategies can shift drastically.
            </p>
            <blockquote>
              Back in-form teams with depth, especially in the MLS.
            </blockquote>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "How reliable is Football Genie AI?",
    type: "general",
    answer: `Football Genie AI is built to deliver precise and data-driven predictions. Each tip comes with a rating (Safe, Value, Risky) and a probability percentage to help you assess its reliability.

While our AI delivers impressive accuracy, it’s essential to use its predictions as a guide rather than blindly following every tip. Remember, football remains an unpredictable sport, and informed decision-making is key!`,
    content: (
      <>
        <p>
          Football Genie AI is built to deliver precise and data-driven
          predictions. Each tip comes with a rating (Safe, Value, Risky) and a
          probability percentage to help you assess its reliability.
        </p>
        <p>
          While our AI delivers impressive accuracy, it’s essential to use its
          predictions as a{" "}
          <strong>guide rather than blindly following every tip</strong>.
          Remember, football remains an unpredictable sport, and informed
          decision-making is key!
        </p>
      </>
    ),
  },
  {
    question: "How does Football Genie AI work?",
    type: "general",
    answer: `Football Genie AI combines advanced statistical models and Generative AI technology (like ChatGPT) to evaluate matches comprehensively. It analyzes historical data, recent performance, team news, injuries, and fan sentiment to create unbiased, accurate predictions.

This hybrid approach ensures that both quantitative and qualitative factors are taken into account for every prediction.`,
    content: (
      <>
        <p>
          Football Genie AI combines advanced statistical models and{" "}
          <strong>Generative AI</strong> technology (like ChatGPT) to evaluate
          matches comprehensively. It analyzes historical data, recent
          performance, team news, injuries, and fan sentiment to create
          unbiased, accurate predictions.
        </p>
        <p>
          This hybrid approach ensures that both quantitative and qualitative
          factors are taken into account for every prediction.
        </p>
      </>
    ),
  },
  {
    question: "What leagues are supported?",
    type: "general",
    answer: `Football Genie currently supports:

European Leagues: Premier League, La Liga, Serie A, Bundesliga, Ligue 1.
International Tournaments: UEFA Champions League, Europa League, Euro Championship, Copa América, Super Cup.

We’re actively expanding our coverage to include more leagues—stay tuned for updates!`,
    content: (
      <>
        <p>Football Genie currently supports:</p>
        <ul>
          <li>
            <p>
              <strong>European Leagues:</strong> Premier League, La Liga, Serie
              A, Bundesliga, Ligue 1.
            </p>
          </li>
          <li>
            <p>
              <strong>International Tournaments:</strong> UEFA Champions League,
              Europa League, Euro Championship, Copa América, Super Cup.
            </p>
          </li>
        </ul>
        <p>
          We’re actively expanding our coverage to include more leagues—stay
          tuned for updates!
        </p>
      </>
    ),
  },
  {
    question: "How does the AI evaluate teams and players?",
    type: "general",
    answer: `Our AI analyzes vast metrics, including:

Team Stats: Possession, xG, recent form, momentum, motivations, and other key statistics.
Player Performance: Shots, assists, saves, dribbles, and more.
External Factors: Managerial changes, injuries, fan sentiment, team strengths and weaknesses, and tactical matchups.

By merging statistical and contextual data, Football Genie fully (if not better) replicates the analysis of an expert analyst.`,
    content: (
      <>
        <p>Our AI analyzes vast metrics, including:</p>
        <ul>
          <li>
            <strong>Team Stats:</strong> Possession, xG, recent form, momentum,
            motivations, and other key statistics.
          </li>
          <li>
            <strong>Player Performance:</strong> Shots, assists, saves,
            dribbles, and more.
          </li>
          <li>
            <strong>External Factors:</strong> Managerial changes, injuries, fan
            sentiment, team strengths and weaknesses, and tactical matchups.
          </li>
        </ul>
        <p>
          By merging statistical and contextual data, Football Genie fully (if
          not better) replicates the analysis of an expert analyst.
        </p>
      </>
    ),
  },
  {
    question: "What is the Predictability Score, and how should I use it?",
    type: "general",
    answer: `The Predictability Score rates how likely a match outcome is predictable, on a scale from 1 (unpredictable) to 10 (highly predictable). Use this score to decide whether to bet on match results, rather than focusing on less predictable markets like goals or corners. High scores suggest a safer betting environment.`,
    content: (
      <>
        <p>
          The Predictability Score rates how likely a match outcome is
          predictable, on a scale from <strong>1 (unpredictable)</strong> to{" "}
          <strong>10 (highly predictable)</strong>. Use this score to decide
          whether to bet on match results, rather than focusing on less
          predictable markets like goals or corners. High scores suggest a safer
          betting environment.
        </p>
      </>
    ),
  },
  {
    question: "Why is there a difference between cup matches and league games?",
    type: "general",
    answer: `Cup matches often involve unfamiliar team matchups and rotated lineups, creating more variability. In contrast, league games follow established dynamics, offering more consistent data for reliable predictions. Always approach cup games with extra caution.`,
    content: (
      <>
        <p>
          Cup matches often involve unfamiliar team matchups and rotated
          lineups, creating more variability. In contrast, league games follow
          established dynamics, offering more consistent data for reliable
          predictions. Always approach cup games with extra caution.
        </p>
      </>
    ),
  },
  {
    question: "How many tips are provided per day?",
    type: "tips",
    answer: `Every day, Football Genie offers around 30 carefully selected tips, covering approximately 7-8 football matches. This steady stream of tips ensures you have plenty of opportunities to make informed bets and stay ahead in the game.`,
    content: (
      <>
        <p>
          Every day, Football Genie offers <strong>around 30</strong> carefully
          selected tips, covering approximately 7-8 football matches. This
          steady stream of tips ensures you have plenty of opportunities to make
          informed bets and stay ahead in the game.
        </p>
      </>
    ),
  },
  {
    question:
      "What’s the difference between Value-Based and Result-Oriented tips?",
    type: "tips",
    answer: `At Football Genie, we provide two distinct types of tips tailored to different betting strategies:

Value-Based Tips: These tips focus on finding bets with higher odds than the actual probability of the outcome, ensuring more consistent and reliable returns. We offer fewer Value-Based tips, concentrated on Markets like Goals Over/Under, Match Winner, and Double Chance. This approach helps you maximize your potential returns over time with greater consistency.

Result-Oriented Tips: These tips aim to predict a wider range of match outcomes, providing more tips but with lower accuracy. Result-Oriented tips cover a broader spectrum of markets, allowing for more betting opportunities and the potential for higher rewards.

Whether you prefer the steady consistency of Value-Based tips or the expansive opportunities of Result-Oriented tips, Football Genie has the perfect options to match your betting style and goals.`,
    content: (
      <>
        <p>
          At Football Genie, we provide two distinct types of tips tailored to
          different betting strategies:
        </p>
        <ul>
          <li>
            <strong>Value-Based Tips:</strong> These tips focus on finding bets
            with higher odds than the actual probability of the outcome,
            ensuring more consistent and reliable returns. We offer fewer
            Value-Based tips, concentrated on Markets like Goals Over/Under,
            Match Winner, and Double Chance. This approach helps you maximize
            your potential returns over time with greater consistency.
          </li>
          <li>
            <strong>Result-Oriented Tips:</strong> These tips aim to predict a
            wider range of match outcomes, providing more tips but with lower
            accuracy. Result-Oriented tips cover a broader spectrum of markets,
            allowing for more betting opportunities and the potential for higher
            rewards.
          </li>
        </ul>
        <p>
          Whether you prefer the steady consistency of Value-Based tips or the
          expansive opportunities of Result-Oriented tips, Football Genie has
          the perfect options to match your betting style and goals.
        </p>
      </>
    ),
  },
  {
    question: 'What does it mean if a tip is "risky" or "safe"?',
    type: "tips",
    answer: `Our tips come with a risk indicator to help you make informed betting decisions:

Super Safe Tips: These tips have the highest probability of success based on comprehensive analysis and historical data. They are designed for bettors who prioritize maximum reliability and minimal risk.

Safe Tips: These tips have a high probability of success, making them great for bettors who prefer lower risk and more lower, but consistent returns.

Value Tips: These tips offer a balanced approach, focusing on finding value where the odds are favorable. They provide a good mix of risk and reward, ideal for bettors looking to maximize their potential returns without taking on excessive risk.

Risky Tips: These tips offer higher potential rewards but come with increased uncertainty. They are perfect for those looking to maximize their winnings with a bit more adventurous betting.

Choose the type of tip that aligns with your betting style and risk tolerance to enhance your overall betting experience.`,
    content: (
      <>
        <p>
          Our tips come with a risk indicator to help you make informed betting
          decisions:
        </p>
        <ul>
          <li>
            <strong>Super Safe Tips:</strong> These tips have the highest
            probability of success based on comprehensive analysis and
            historical data. They are designed for bettors who prioritize
            maximum reliability and minimal risk.
          </li>
          <li>
            <strong>Safe Tips:</strong> These tips have a high probability of
            success, making them great for bettors who prefer lower risk and
            more lower, but consistent returns.
          </li>
          <li>
            <strong>Value Tips:</strong> These tips offer a balanced approach,
            focusing on finding value where the odds are favorable. They provide
            a good mix of risk and reward, ideal for bettors looking to maximize
            their potential returns without taking on excessive risk.
          </li>
          <li>
            <strong>Risky Tips:</strong> These tips offer higher potential
            rewards but come with increased uncertainty. They are perfect for
            those looking to maximize their winnings with a bit more adventurous
            betting.
          </li>
        </ul>
        <p>
          Choose the type of tip that aligns with your betting style and risk
          tolerance to enhance your overall betting experience.
        </p>
      </>
    ),
  },
  {
    question: "How accurate are past tips, and where can I find this data?",
    type: "tips",
    answer: `Our probability percentages are highly accurate. For instance, when you filter tips with an 80%-100% probability, you can expect a hit rate of approximately 80%. This demonstrates the reliability and precision of our advanced analysis and expertise in football betting.

For the most up-to-date performance statistics and detailed reports, visit our AI Performance page. Here, you can explore the latest data that showcases the effectiveness and reliability of our tips.`,
    content: (
      <>
        <p>
          Our probability percentages are highly accurate. For instance, when
          you filter tips with an 80%-100% probability, you can expect a hit
          rate of approximately 80%. This demonstrates the reliability and
          precision of our advanced analysis and expertise in football betting.
        </p>
        <p>
          For the most up-to-date performance statistics and detailed reports,
          visit our{" "}
          <a href={`${process.env.NEXT_PUBLIC_APP_URL}/ai-performance`}>
            AI Performance
          </a>{" "}
          page. Here, you can explore the latest data that showcases the
          effectiveness and reliability of our tips.
        </p>
      </>
    ),
  },
  {
    question: "When are tips made available?",
    type: "tips",
    answer: `Tips are generated daily at 5 AM CET for matches happening the following day, ensuring you have access to two days’ worth of tips in advance. This gives you plenty of time to strategize!`,
    content: (
      <>
        <p>
          Tips are generated daily at 5 AM CET for matches happening the
          following day, ensuring you have access to two days’ worth of tips in
          advance. This gives you plenty of time to strategize!
        </p>
      </>
    ),
  },
  {
    question:
      "How should I interpret recommendations with high or low predictability?",
    type: "tips",
    answer: `The probability percentage indicates the likelihood of a tip’s success:

High Probability (e.g., 85%-100%): These tips are more likely to succeed and are safer choices for consistent returns, but likely with lower odds.

Low Probability (e.g., 50%-70%): These tips carry more risk but may offer higher potential rewards. Use them with caution based on your risk tolerance.

Understanding the probability percentages can help you make more informed and strategic betting decisions.`,
    content: (
      <>
        <p>
          The probability percentage indicates the likelihood of a tip’s
          success:
        </p>
        <ul>
          <li>
            <strong>High Probability (e.g., 85%-100%):</strong> These tips are
            more likely to succeed and are safer choices for consistent returns,
            but likely with lower odds.
          </li>
          <li>
            <strong>Low Probability (e.g., 50%-70%):</strong> These tips carry
            more risk but may offer higher potential rewards. Use them with
            caution based on your risk tolerance.
          </li>
        </ul>
        <p>
          Understanding the probability percentages can help you make more
          informed and strategic betting decisions.
        </p>
      </>
    ),
  },
  {
    question:
      "What happens if a player is unexpectedly injured after a tip is released?",
    type: "tips",
    answer: `Football Genie cannot modify tips after release. We recommend reviewing late-breaking news and adjusting your bets accordingly when significant changes, such as key injuries, occur. Always stay informed!

👉 Tip: Significant odds changes can indicate important news or developments. Monitor odds movements to stay ahead.`,
    content: (
      <>
        <p>
          Football Genie cannot modify tips after release. We recommend
          reviewing late-breaking news and adjusting your bets accordingly when
          significant changes, such as key injuries, occur. Always stay
          informed!
        </p>

        <blockquote>
          👉 <strong>Tip: </strong> Significant odds changes can indicate
          important news or developments. Monitor odds movements to stay ahead.
        </blockquote>
      </>
    ),
  },
  {
    question: "How should beginners approach Football Genie’s tips?",
    type: "tips",
    answer: `Our AI is primarily designed for bettors with a mid-level understanding of football trends and betting strategies. Our platform provides detailed match analyses and AI-driven insights, which require some basic football knowledge to interpret effectively.

If you're a beginner, we recommend taking time to familiarize yourself with football betting concepts, such as match dynamics, season periods, and the types of bets available. Reviewing our match analyses thoroughly before placing any bets is essential to make informed decisions.

Football Genie is best suited for those ready to leverage advanced tools to refine their betting strategy!`,
    content: (
      <>
        <p>
          Our AI is primarily designed for bettors with a mid-level
          understanding of football trends and betting strategies. Our platform
          provides detailed match analyses and AI-driven insights, which require
          some basic football knowledge to interpret effectively.
        </p>
        <p>
          If you&#39;re a beginner, we recommend taking time to familiarize
          yourself with football betting concepts, such as match dynamics,
          season periods and the types of bets available. Reviewing our match
          analyses thoroughly before placing any bets is essential to make
          informed decisions.
        </p>
        <p>
          Football Genie is best suited for those ready to leverage advanced
          tools to refine their betting strategy!
        </p>
      </>
    ),
  },
  {
    question: "How many tips should I bet on at once?",
    type: "tips",
    answer: `We recommend selecting a manageable number of tips (maximum 3-5 per day) that align with your strategy and bankroll. Avoid overloading your bets to maintain focus and control.

Quality over quantity always wins! 🏆`,
    content: (
      <>
        <p>
          We recommend selecting a manageable number of tips (maximum 3-5 per
          day) that align with your strategy and bankroll. Avoid overloading
          your bets to maintain focus and control.
        </p>
        <p>Quality over quantity always wins! 🏆</p>
      </>
    ),
  },
  {
    question: "What are the best leagues to bet on?",
    type: "tips",
    answer: `The Premier League and Bundesliga consistently stand out as the best leagues to bet on with Football Genie. These leagues provide stable data and high accuracy in our predictions, making them ideal for informed betting.

For the latest performance metrics, including hit rates and ROI across all supported leagues, visit our AI Performance page. Stay updated on the top-performing leagues and adjust your strategy accordingly.`,
    content: (
      <>
        <p>
          The <strong>Premier League</strong> and <strong>Bundesliga</strong>{" "}
          consistently stand out as the best leagues to bet on with Football
          Genie. These leagues provide stable data and high accuracy in our
          predictions, making them ideal for informed betting.
        </p>
        <p>
          For the latest performance metrics, including hit rates and ROI across
          all supported leagues, visit our{" "}
          <a href={`${process.env.NEXT_PUBLIC_APP_URL}/ai-performance`}>
            AI Performance
          </a>{" "}
          page. Stay updated on the top-performing leagues and adjust your
          strategy accordingly.
        </p>
      </>
    ),
  },
  {
    question: "Is automatic betting possible?",
    type: "strategies",
    answer: `Currently, Football Genie AI does not support automatic betting. While our platform provides highly accurate predictions and personalized tips, all bet placements must be done manually by users on their chosen betting platforms. This ensures users maintain full control over their betting decisions and bankroll management.`,
    content: (
      <>
        <p>
          Currently, Football Genie AI does not support automatic betting. While
          our platform provides highly accurate predictions and personalized
          tips, all bet placements must be done manually by users on their
          chosen betting platforms. This ensures users maintain full control
          over their betting decisions and bankroll management.
        </p>
      </>
    ),
  },
  {
    question: "What are the recommended bankroll management practices?",
    type: "strategies",
    answer: `Effective bankroll management is essential for sustainable betting. We recommend:

Set a Budget: Allocate a specific amount of money for betting and never exceed it.
Use a Percentage-Based Stake: Bet a small, fixed percentage (e.g., 1-5%) of your bankroll per bet to mitigate losses during losing streaks.
Avoid Chasing Losses: Stick to your strategy, and do not increase bet sizes impulsively after losses.
Track Your Bets: Keep a record of all your bets to analyze performance and make informed adjustments to your strategy.`,
    content: (
      <>
        <p>
          Effective bankroll management is essential for sustainable betting. We
          recommend:
        </p>
        <ul>
          <li>
            <strong>Set a Budget:</strong> Allocate a specific amount of money
            for betting and never exceed it.
          </li>
          <li>
            <strong>Use a Percentage-Based Stake:</strong> Bet a small, fixed
            percentage (e.g., 1-5%) of your bankroll per bet to mitigate losses
            during losing streaks.
          </li>
          <li>
            <strong>Avoid Chasing Losses:</strong> Stick to your strategy, and
            do not increase bet sizes impulsively after losses.
          </li>
          <li>
            <strong>Track Your Bets:</strong> Keep a record of all your bets to
            analyze performance and make informed adjustments to your strategy.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "What is the Kelly Criterion, and why is it important?",
    type: "strategies",
    answer: `The Kelly Criterion is a mathematical formula used to calculate the optimal stake size for a bet based on the probability of winning and the odds offered. It helps bettors maximize profits over time while minimizing the risk of ruin. Football Genie applies a conservative 0.25 Kelly fraction by default, which is adjustable in the app to suit your risk tolerance.

The Kelly formula is as follows:

Stake (%) = [(Probability × (Odds - 1)) - (1 - Probability)] ÷ (Odds - 1)

Here’s an example calculation:

Probability of Winning: 70% (0.7)
Odds Offered: 1.8

Using the formula:

Stake (%) = [(0.7 × (1.8 - 1)) - (1 - 0.7)] ÷ (1.8 - 1)
Stake (%) = [(0.7 × 0.8) - 0.3] ÷ 0.8
Stake (%) = (0.56 - 0.3) ÷ 0.8
Stake (%) = 0.26 ÷ 0.8
Stake (%) = 0.325 or 32.5%

With Football Genie’s default 0.25 Kelly fraction, the stake would be:

32.5% × 0.25 = 8.125%

This means you should bet approximately 8.1% of your bankroll.

**Note:** The Kelly fraction can be configured in the app’s settings, allowing you to choose a more aggressive or conservative staking approach based on your preferences.`,
    content: (
      <>
        <p>
          The Kelly Criterion is a mathematical formula used to calculate the
          optimal stake size for a bet based on the probability of winning and
          the odds offered. It helps bettors maximize profits over time while
          minimizing the risk of ruin. Football Genie applies a conservative{" "}
          <strong>0.25 Kelly fraction</strong> by default, which is adjustable
          in the app to suit your risk tolerance.
        </p>
        <p>The Kelly formula is as follows:</p>
        <p>
          <strong>
            Stake (%) = [(Probability × (Odds - 1)) - (1 - Probability)] ÷ (Odds
            - 1)
          </strong>
        </p>
        <p>Here’s an example calculation:</p>
        <ul>
          <li>
            <strong>Probability of Winning:</strong> 70% (0.7)
          </li>
          <li>
            <strong>Odds Offered:</strong> 1.8
          </li>
        </ul>
        <p>Using the formula:</p>
        <pre>
          <code>Stake (%) = [(0.7 × (1.8 - 1)) - (1 - 0.7)] ÷ (1.8 - 1)</code>
        </pre>
        <p>Calculation:</p>
        <pre>
          <code>Stake (%) = [(0.7 × 0.8) - 0.3] ÷ 0.8</code>
        </pre>
        <pre>
          <code>Stake (%) = (0.56 - 0.3) ÷ 0.8</code>
        </pre>
        <pre>
          <code>Stake (%) = 0.26 ÷ 0.8</code>
        </pre>
        <pre>
          <code>Stake (%) = 0.325 or 32.5%</code>
        </pre>
        <p>
          With Football Genie’s default <strong>0.25 Kelly fraction</strong>,
          the stake would be:
        </p>
        <pre>
          <code>32.5% × 0.25 = 8.125%</code>
        </pre>
        <p>This means you should bet approximately 8.1% of your bankroll.</p>
        <blockquote>
          <strong>Note:</strong> The Kelly fraction can be configured in the
          app’s settings, allowing you to choose a more aggressive or
          conservative staking approach based on your preferences.
        </blockquote>
      </>
    ),
  },
  {
    question: "Can I apply custom settings in the tip filters?",
    type: "strategies",
    answer: `Yes, Football Genie provides an extensive range of filtering options to help you tailor your betting strategy. You can apply multiple filters to find tips that perfectly align with your preferences. Available filtering options include:

Tip Rating: Choose from Safe, Super Safe, or Value-based tips depending on your risk tolerance.
League: Focus on specific competitions or regions of your choice.
Tip Category: Filter by categories such as match winners, over/under goals, and more.
Odds: Set a range for minimum and maximum odds to match your betting style.
Edge: View tips with specific value margins (the difference between AI probability and bookmaker odds).
Stake: Filter tips based on recommended stake percentages.
Probability: Find tips with high AI-predicted probabilities, e.g., 85-100%.
Predictability: Assess the stability and consistency of a match based on AI insights.
Best Choice: Highlight the most promising tips based on combined factors.
Safe for Betting: Identify tips with minimal risk indicators.
Match Importance: Focus on high-stakes games (e.g., title races or relegation battles).

**Save Your Strategy:** You can create and save filter sets—called *Strategies*—for quick reuse. For example, you can save a filter set with:

Probability: 85-100%
Tip Rating: Safe or Super Safe
League: Your preferred league

This feature allows you to instantly apply your favorite filters and consistently find the most relevant tips for your betting approach.`,
    content: (
      <>
        <p>
          Yes, Football Genie provides an extensive range of filtering options
          to help you tailor your betting strategy. You can apply multiple
          filters to find tips that perfectly align with your preferences.
          Available filtering options include:
        </p>
        <ul>
          <li>
            <strong>Tip Rating:</strong> Choose from Safe, Super Safe, or
            Value-based tips depending on your risk tolerance.
          </li>
          <li>
            <strong>League:</strong> Focus on specific competitions or regions
            of your choice.
          </li>
          <li>
            <strong>Tip Category:</strong> Filter by categories such as match
            winners, over/under goals, and more.
          </li>
          <li>
            <strong>Odds:</strong> Set a range for minimum and maximum odds to
            match your betting style.
          </li>
          <li>
            <strong>Edge:</strong> View tips with specific value margins (the
            difference between AI probability and bookmaker odds).
          </li>
          <li>
            <strong>Stake:</strong> Filter tips based on recommended stake
            percentages.
          </li>
          <li>
            <strong>Probability:</strong> Find tips with high AI-predicted
            probabilities, e.g., 85-100%.
          </li>
          <li>
            <strong>Predictability:</strong> Assess the stability and
            consistency of a match based on AI insights.
          </li>
          <li>
            <strong>Best Choice:</strong> Highlight the most promising tips
            based on combined factors.
          </li>
          <li>
            <strong>Safe for Betting:</strong> Identify tips with minimal risk
            indicators.
          </li>
          <li>
            <strong>Match Importance:</strong> Focus on high-stakes games (e.g.,
            title races or relegation battles).
          </li>
        </ul>
        <p>
          <strong>Save Your Strategy:</strong> You can create and save filter
          sets—called <em>Strategies</em>—for quick reuse. For example, you can
          save a filter set with:
        </p>
        <ul>
          <li>
            <strong>Probability:</strong> 85-100%
          </li>
          <li>
            <strong>Tip Rating:</strong> Safe or Super Safe
          </li>
          <li>
            <strong>League:</strong> Your preferred league
          </li>
        </ul>
        <p>
          This feature allows you to instantly apply your favorite filters and
          consistently find the most relevant tips for your betting approach.
        </p>
      </>
    ),
  },
  {
    question:
      "How does the AI perform during unpredictable periods, like the start of the season?",
    type: "strategies",
    answer: `Unpredictable periods, such as the start of the season, bring unique challenges like new player signings, evolving tactics, and varying team form. Football Genie accounts for these factors through its non-statistical model, which evaluates news, sentiment, and contextual insights. While accuracy may dip during such times, we advise focusing on safer betting strategies and closely monitoring team performance updates provided by our AI.`,
    content: (
      <>
        <p>
          Unpredictable periods, such as the start of the season, bring unique
          challenges like new player signings, evolving tactics, and varying
          team form. Football Genie accounts for these factors through its
          non-statistical model, which evaluates news, sentiment, and contextual
          insights. While accuracy may dip during such times, we advise focusing
          on safer betting strategies and closely monitoring team performance
          updates provided by our AI.
        </p>
      </>
    ),
  },
  {
    question: "What are the most common risks in sports betting?",
    type: "strategies",
    answer: `Sports betting comes with inherent risks. Here are the most common ones:

Overconfidence: Betting large sums based on limited data or past successes can lead to significant losses.
Lack of Research: Ignoring key factors like injuries, form, and team news can result in poor decisions.
Emotional Betting: Placing bets based on personal bias or frustration can derail a disciplined approach.
Unpredictability: External factors like weather, referee decisions, or player errors can affect outcomes.

Football Genie helps mitigate these risks by providing well-researched tips, detailed analysis, and personalized strategies.`,
    content: (
      <>
        <p>
          Sports betting comes with inherent risks. Here are the most common
          ones:
        </p>
        <ul>
          <li>
            <strong>Overconfidence:</strong> Betting large sums based on limited
            data or past successes can lead to significant losses.
          </li>
          <li>
            <strong>Lack of Research:</strong> Ignoring key factors like
            injuries, form, and team news can result in poor decisions.
          </li>
          <li>
            <strong>Emotional Betting:</strong> Placing bets based on personal
            bias or frustration can derail a disciplined approach.
          </li>
          <li>
            <strong>Unpredictability:</strong> External factors like weather,
            referee decisions, or player errors can affect outcomes.
          </li>
        </ul>
        <p>
          Football Genie helps mitigate these risks by providing well-researched
          tips, detailed analysis, and personalized strategies.
        </p>
      </>
    ),
  },
  {
    question: "Are there free trial options?",
    type: "subscription",
    answer: `Yes! We offer a free plan without any commitment. With this plan, users can unlock tips for up to 3 matches daily, giving everyone the chance to explore Football Genie AI without any cost.`,
    content: (
      <>
        <p>
          Yes! We offer a free plan without any commitment. With this plan,
          users can unlock tips for up to 3 matches daily, giving everyone the
          chance to explore Football Genie AI without any cost.
        </p>
      </>
    ),
  },
  {
    question: "What subscription plans are available?",
    type: "subscription",
    answer: `We provide three distinct plans to suit different betting styles:

Free Plan: Perfect for beginners and casual bettors. Includes:
- Tips for up to 3 matches per day
- In-depth match analysis
- No commitment required

Standard Plan: Ideal for bettors treating betting as an investment strategy. Available at €4.5 weekly or €14.9 monthly, this plan includes:
- Unlimited tips
- 24/7 support
- Bankroll management
- Custom betting strategies
- Personalization

Enterprise Plan: Designed for professionals and businesses seeking AI-driven betting solutions. Includes everything in the Standard Plan plus:
- Developer API access
- Custom integrations
- Marketing and automation tools`,
    content: (
      <>
        <p>We provide three distinct plans to suit different betting styles:</p>
        <ul>
          <li>
            <strong>Free Plan</strong>: Perfect for beginners and casual
            bettors. Includes:
            <ul>
              <li>Tips for up to 3 matches per day</li>
              <li>In-depth match analysis</li>
              <li>No commitment required</li>
            </ul>
          </li>
          <li>
            <strong>Standard Plan</strong>: Ideal for bettors treating betting
            as an investment strategy. Available at €4.5 weekly or €14.9
            monthly, this plan includes:
            <ul>
              <li>Unlimited tips</li>
              <li>24/7 support</li>
              <li>Bankroll management</li>
              <li>Custom betting strategies</li>
              <li>Personalization</li>
            </ul>
          </li>
          <li>
            <strong>Enterprise Plan</strong>: Designed for professionals and
            businesses seeking AI-driven betting solutions. Includes everything
            in the Standard Plan plus:
            <ul>
              <li>Developer API access</li>
              <li>Custom integrations</li>
              <li>Marketing and automation tools</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    question:
      "How are payments processed, and what payment methods do you accept?",
    type: "subscription",
    answer: `All payments are securely processed through Stripe, ensuring maximum safety and reliability. We support various payment methods, including Apple Pay, Google Pay, PayPal, and major credit or debit cards.`,
    content: (
      <>
        <p>
          All payments are securely processed through Stripe, ensuring maximum
          safety and reliability. We support various payment methods, including
          Apple Pay, Google Pay, PayPal, and major credit or debit cards.
        </p>
      </>
    ),
  },
  {
    question: "Are there occasional free tips?",
    type: "subscription",
    answer: `Yes! You can enjoy daily free tips through our Free Plan. Additionally, join our vibrant Telegram Community for more free tips shared by our community of bettors.`,
    content: (
      <>
        <p>
          Yes! You can enjoy daily free tips through our Free Plan.
          Additionally, join our vibrant{" "}
          <a href="https://t.me/+vMSyhj7q38xkZDRk">Telegram Community</a> for
          more free tips shared by our community of bettors.
        </p>
      </>
    ),
  },
  {
    question: "Is the platform mobile-friendly?",
    type: "support",
    answer: `Yes, Football Genie is fully mobile-friendly! You can easily access all features and insights on your smartphone or tablet, ensuring you never miss an update or tip, no matter where you are. 🚀`,
    content: (
      <>
        <p>
          Yes, Football Genie is fully mobile-friendly! You can easily access
          all features and insights on your smartphone or tablet, ensuring you
          never miss an update or tip, no matter where you are. 🚀
        </p>
      </>
    ),
  },
  {
    question: "How can i request a new feature?",
    type: "support",
    answer: `We love hearing your ideas! To request a new feature, simply visit our Feature Requests page. Share your thoughts, and our team will review them for future updates.`,
    content: (
      <>
        <p>
          We love hearing your ideas! To request a new feature, simply visit our{" "}
          <a
            href="https://football-genie.canny.io/feature-requests"
            target="_blank"
          >
            Feature Requests page
          </a>
          . Share your thoughts, and our team will review them for future
          updates.
        </p>
      </>
    ),
  },
  {
    question: "Is there a Football Genie mobile app?",
    type: "support",
    answer: `Not yet, but it's in our plans! We're working to deliver a seamless mobile app experience in the future. For now, you can enjoy our fully optimized mobile website. Stay tuned! 📱`,
    content: (
      <>
        <p>
          Not yet, but it&#39;s in our plans! We&#39;re working to deliver a
          seamless mobile app experience in the future. For now, you can enjoy
          our fully optimized mobile website. Stay tuned! 📱
        </p>
      </>
    ),
  },
  {
    question: "How can I contact the support team?",
    type: "support",
    answer: `Need help? Our support team is here for you! Reach us via email at info@football-genie.com or message us on our Telegram channel.

You can use the Contact Form found on this page. We're always ready to assist.`,
    content: (
      <>
        <p>
          Need help? Our support team is here for you! Reach us via email at{" "}
          <a href="mailto:info@football-genie.com">info@football-genie.com</a>{" "}
          or message us on our{" "}
          <a href="https://t.me/football_genie" target="_blank">
            Telegram channel
          </a>
          .
        </p>
        <p>
          You can use the <a href="#contact">Contact Form</a> found in this
          page. We&#39;re always ready to assist.
        </p>
      </>
    ),
  },
  {
    question: "What should I do if I encounter a technical issue?",
    type: "support",
    answer: `If you face a technical issue, don’t worry! Try refreshing your browser or clearing your cache. If the problem persists, contact us at info@football-genie.com. Provide details like the error message and steps to reproduce it so we can resolve it quickly. 🔧`,
    content: (
      <>
        <p>
          If you face a technical issue, don’t worry! Try refreshing your
          browser or clearing your cache. If the problem persists, contact us at{" "}
          <a href="mailto:info@football-genie.com">info@football-genie.com</a>.
          Provide details like the error message and steps to reproduce it so we
          can resolve it quickly. 🔧
        </p>
      </>
    ),
  },
  {
    question: "How do you ensure data and payment security?",
    type: "support",
    answer: `Your safety is our priority. Football Genie uses AWS Services for data security and Stripe for secure payments. We adhere to strict privacy policies and ensure all transactions are encrypted and compliant with industry standards. Your trust means everything to us.`,
    content: (
      <>
        <p>
          Your safety is our priority. Football Genie uses{" "}
          <strong>AWS Services</strong> for data security and{" "}
          <strong>Stripe</strong> for secure payments. We adhere to strict
          privacy policies and ensure all transactions are encrypted and
          compliant with industry standards. Your trust means everything to us.
        </p>
      </>
    ),
  },
];
