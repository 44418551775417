import { Stack } from "@ui/layout";
import { ReadmeContent } from "@components/ReadmeContent/ReadmeContent";
import { Icon, Tag } from "@ui/elements";
import classNames from "classnames";
import { useFixtureContext } from "@components/FixtureCard/context";
import React from "react";

export const CorrectScore = () => {
  const {
    source: { data },
    fixture,
  } = useFixtureContext();

  const predictions = data?.data.correct_score?.split(" or ") || [
    data?.data.correct_score,
  ];
  const score = fixture.score.fulltime;

  return (
    <Stack>
      <h4 className="sr-only">Correct Score Prediction</h4>
      <Stack direction="horizontal">
        {predictions.map((prediction, i) => {
          const [home, away] = prediction?.split("-") || [];

          const isWinner =
            home === String(score.home) && away === String(score.away);

          return (
            <Tag
              as="p"
              className={classNames("flex gap-xs", {
                "text-semibold border-green-600 bg-green-600 text-white dark:border-green-700/40 dark:bg-green-500/20 dark:text-gray-100":
                  isWinner,
                "border border-gray-200 bg-white dark:border-gray-800 dark:bg-neutral-700 dark:text-neutral-200":
                  !isWinner,
              })}
              role="button"
              aria-pressed={isWinner}
              aria-label={`Prediction ${home} - ${away} ${isWinner ? "is correct" : "is not correct"}`}
              key={`prediction-${i}-${prediction}`}
            >
              {isWinner && (
                <span
                  className="-ml-2 flex items-center gap-xs"
                  aria-hidden="true"
                >
                  <Icon
                    icon="CheckCircle"
                    className="size-5"
                    weight="duotone"
                  />
                </span>
              )}
              <span>
                <strong className="font-normal">
                  {home} - {away}
                </strong>
              </span>
            </Tag>
          );
        })}
      </Stack>
      <ReadmeContent
        className="prose prose-sm prose-slate dark:prose-invert"
        content={data?.data.correct_score_reasoning}
      />
    </Stack>
  );
};
