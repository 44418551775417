import { Stack } from "@ui/layout";
import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Icon, Tag, Title } from "@ui/elements";
import Image, { StaticImageData } from "next/image";

import facebookLogo from "./facebook.webp";
import googleLogo from "./google.png";
import telegramLogo from "./telegram.png";
import twitterLogo from "./x-logo.png";
import instagramLogo from "./instagram.png";

// Reviews
import borsik from "../../assets/reviews/borsik_g.jpg";
import boncz_1 from "../../assets/reviews/boncz_a_1.jpeg";
import boncz_2 from "../../assets/reviews/boncz_a_2.jpeg";
import boncz_3 from "../../assets/reviews/boncz_a_3.jpeg";

import classNames from "classnames";

import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Container } from "@components/Container/Container";
import { LightBox } from "@components/LightBox/LightBox";

const StarFeedback: FC<{ stars: number }> = ({ stars }) => {
  return (
    <div className="flex gap-1">
      {Array.from({ length: 5 }).map((_, index) => (
        <Icon
          key={`star-${index}`}
          icon="Star"
          weight={index < stars ? "fill" : "regular"}
          className={classNames(
            index < stars
              ? "text-yellow-400"
              : "text-gray-300 dark:text-neutral-400",
            "size-4",
          )}
        />
      ))}
    </div>
  );
};

type TestimonialSource =
  | "telegram"
  | "facebook"
  | "twitter"
  | "google"
  | "instagram";

const LogoSourceMap: Record<TestimonialSource, StaticImageData> = {
  telegram: telegramLogo,
  facebook: facebookLogo,
  twitter: twitterLogo,
  google: googleLogo,
  instagram: instagramLogo,
};

const SourceNameMap: Record<TestimonialSource, string> = {
  telegram: "Telegram",
  facebook: "Facebook",
  twitter: "Twitter",
  google: "Google",
  instagram: "Instagram",
};

type TestimonialItemProps = {
  name: string;
  text: string;
  type: TestimonialSource;
  rating: number;
  images?: StaticImageData[];
  cite?: string;
};

const TestimonialItem: FC<TestimonialItemProps> = ({
  name,
  text,
  type,
  rating,
  images,
}) => {
  const [hydrated, setHydrated] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      const isTextOverflowing = el.scrollHeight > el.clientHeight;
      setIsOverflowing(isTextOverflowing); // Potentially triggers re-renders
    }
  }, []);

  useEffect(() => {
    setHydrated(true);
  }, []);

  return (
    <blockquote className="p-2 md:w-full" cite="">
      <Stack
        spacing="lg"
        className="min-h-60 rounded-lg border border-gray-200 bg-white p-xl dark:border-neutral-700 dark:bg-neutral-800"
      >
        <Stack>
          <StarFeedback stars={rating} />
          <div className="flex flex-col items-start gap-xxs">
            <p
              id={`testimonial-text-${name.toLowerCase()}-${text.split(" ")[0].toLowerCase()}`}
              ref={textRef}
              className={classNames("text-gray-500 dark:text-neutral-300", {
                "line-clamp-3": !isExpanded,
              })}
            >
              {(images?.length || 0) > 0 && (
                <LightBox
                  images={images}
                  alt={`Review image by ${name}`}
                  imageClass="float-left mr-2 mt-1 inline size-10 rounded cursor-pointer"
                />
              )}
              {text}
            </p>
            {hydrated && !isExpanded && isOverflowing && (
              <button
                onClick={() => setIsExpanded(true)}
                className="text-blue-500"
                aria-expanded={isExpanded}
                aria-controls={`testimonial-text-${name.toLowerCase()}-${text.split(" ")[0].toLowerCase()}`}
              >
                more
              </button>
            )}
          </div>
        </Stack>
        <footer className="mt-auto flex items-center gap-md">
          <Image
            className="size-9"
            src={LogoSourceMap[type]}
            alt={`${type} logo`}
          />
          <Stack spacing="none">
            <cite className="text-lg font-medium not-italic dark:text-neutral-200">
              - {name}
            </cite>
            <p className="text-sm leading-snug text-gray-400 dark:text-neutral-400">
              {SourceNameMap[type]}
            </p>
          </Stack>
        </footer>
      </Stack>
    </blockquote>
  );
};

const testimonials: TestimonialItemProps[] = [
  {
    name: "Borsik G.",
    text: "Great potential in the Genie bot, great hit-rate, evolving community, and most importantly the developer as well as the operation of the bot is completely transparent.",
    type: "telegram",
    rating: 5,
    images: [borsik],
  },
  {
    name: "Tamás Sz.",
    text: "It is a very valid concept, finding divergence between the real odds and the odds of the betting sites. It looks like Football Genie can estimate closer to the real odds. Also the responsivity and help of the creator is fantastic.",
    type: "telegram",
    rating: 5,
  },
  {
    name: "László V.",
    text: "It's a very useful platform for selecting your own tips, with excellent and numerous filtering parameters. The owner is open to development suggestions and is happy to implement them, so feel free to share your ideas.",
    cite: "https://g.co/kgs/Jznss8R",
    type: "google",
    rating: 5,
  },
  {
    name: "Róbert",
    text: "Team communication is excellent. Tips are backed up with data. They provide the right strategy for the most profitable experience. Excellent community.",
    type: "telegram",
    rating: 4,
  },
  {
    name: "Demeter D.",
    text: "Pretty good succes rate. Easily understandable helps. Transparency from the team.",
    type: "telegram",
    rating: 5,
  },
  {
    name: "Balázs Sz.",
    text: "Very exciting start-up, transparent communication, fast response from the development side.\n\nIt's worth watching, because its performance so far stands out.",
    type: "google",
    cite: "https://g.co/kgs/EXDs9zm",
    rating: 5,
  },
  {
    name: "Róbert",
    text: "I played a littlebit higher bets. I can reach 50% per day. If we have a red day Its pain much more but after a red day always follow few 100% day. Thank you for the work. U have a great community and great tips.",
    type: "telegram",
    rating: 5,
  },
  {
    name: "Kormány Zs.",
    text: "I think better than my tips",
    type: "instagram",
    rating: 5,
  },
  {
    name: "Boncz Á.",
    text: "Authentic, evolving, and in demand.",
    type: "telegram",
    rating: 5,
    images: [boncz_1, boncz_2, boncz_3],
  },
  {
    name: "Muhi M.",
    text: "Amazing tips and top tier information source! This is a must have, absolutely recommended!",
    type: "google",
    cite: "https://g.co/kgs/7kEXipp",
    rating: 5,
  },
];

export const Testimonials = () => {
  const swiperInstance = useRef<SwiperRef>(null);
  const navigationPrevRef = React.useRef<HTMLButtonElement>(null);
  const navigationNextRef = React.useRef<HTMLButtonElement>(null);

  return (
    <section
      className="flex flex-col items-start gap-md px-6 py-14 lg:py-24"
      aria-labelledby="testimonials-heading"
    >
      <Container>
        <Stack spacing="xxl">
          <header
            className="mx-auto flex flex-col items-center justify-center gap-md"
            data-aos="fade-up"
          >
            <Tag
              size="md"
              variant="neutral"
              className="self-start md:self-center"
              as="p"
            >
              Real Experiences
            </Tag>
            <Title
              as="h2"
              styleAs="h1"
              className="w-full md:text-center"
              id="testimonials-heading"
            >
              Trusted by Fans Everywhere
            </Title>
            <Title
              as="h3"
              type="subtitle"
              color="tertiary"
              className="max-w-3xl font-light leading-relaxed tracking-wide md:text-center"
            >
              Find out why users rely on our AI for soccer insights and how
              they&#39;ve elevated their game predictions.
            </Title>
          </header>
          <div
            className="group relative -mx-6 flex max-w-[100vw] items-center gap-lg md:mx-auto md:max-w-full xl:max-w-7xl"
            data-aos="fade-up"
          >
            <Button
              className="hidden h-8 ring-1 ring-gray-200 group-hover:pointer-events-auto group-hover:translate-x-0 group-hover:opacity-100 md:block xl:pointer-events-none xl:translate-x-full xl:opacity-0 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-700"
              onClick={() => {
                swiperInstance?.current?.swiper.slidePrev();
              }}
              aria-label="Previous testimonials"
              tabIndex={0}
            >
              <Icon icon="CaretLeft" className="size-6" />
              <span className="sr-only">Previous testimonials</span>
            </Button>
            <div className="absolute left-0 z-10 h-full w-9 bg-gradient-to-l from-transparent to-white md:left-16 dark:to-neutral-900" />
            <Swiper
              slidesPerView="auto"
              ref={swiperInstance}
              loop={true}
              onBeforeInit={(swiper) => {
                if (navigationPrevRef.current && navigationNextRef.current) {
                  swiper.navigation.prevEl = navigationPrevRef.current;
                  swiper.navigation.nextEl = navigationNextRef.current;
                }
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1280: {
                  slidesPerView: 3,
                },
              }}
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide
                  key={`testimonial-${index}`}
                  className="w-[calc(100%-3rem)] md:w-auto"
                >
                  <TestimonialItem {...testimonial} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="absolute right-0 z-10 h-full w-9 bg-gradient-to-r from-transparent to-white md:right-16 dark:to-neutral-900" />
            <Button
              className="hidden h-8 ring-1 ring-gray-200 group-hover:pointer-events-auto group-hover:translate-x-0 group-hover:opacity-100 md:block xl:pointer-events-none xl:-translate-x-full xl:opacity-0 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-700"
              onClick={() => {
                swiperInstance?.current?.swiper.slideNext();
              }}
              aria-label="Next testimonials"
              tabIndex={0}
            >
              <Icon icon="CaretRight" className="size-6" />
              <span className="sr-only">Next testimonials</span>
            </Button>
          </div>
        </Stack>
      </Container>
    </section>
  );
};
