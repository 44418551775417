import { Stack } from "@ui/layout";
import { Button, Icon } from "@ui/elements";
import React, { useState } from "react";
import Image from "next/image";
import founder from "./founder.jpg";
import { ContactForm } from "@components/Contact/ContactForm";
import { GetInTouchHeader } from "@components/Contact/GetInTouchHeader";
import { copyTextToClipboard } from "@utils";
import { cn } from "@/lib/utils";
import { Container } from "@components/Container/Container";

export const Contact = () => {
  const [emailCopied, setEmailCopied] = useState(false);
  const email = "info@football-genie.com";

  const handleCopyToClipboard = async () => {
    await copyTextToClipboard(email);
    setEmailCopied(true);

    setTimeout(() => {
      setEmailCopied(false);
    }, 1000);
  };

  return (
    <section
      className="px-6 py-9 md:py-24"
      id="contact"
      aria-labelledby="contact-heading"
    >
      <Container>
        <div className="flex w-full flex-col items-center justify-between gap-16 xl:flex-row xl:gap-xxl">
          <div className="flex flex-col gap-xl">
            <GetInTouchHeader
              className="max-w-3xl xl:hidden xl:max-w-xl"
              hasId={true}
            />
            <div className="flex max-w-3xl flex-col gap-16 xl:max-w-xl xl:gap-xxl">
              <article className="flex flex-col gap-xl rounded-xl border border-gray-200 p-7 md:gap-xxl md:p-9 dark:border-neutral-700 dark:bg-neutral-800">
                <header className="flex w-full items-center gap-md">
                  <Image
                    src={founder}
                    className="size-20 rounded-full sm:size-20 md:size-24"
                    alt="Portrait of András Vágner, Founder and Software Engineer at Football Genie"
                    width={80}
                    height={80}
                  />
                  <div className="flex w-full min-w-0 max-w-full flex-col gap-xs md:gap-sm">
                    <Stack spacing="none" className="max-w-full">
                      <h4 className="max-w-full text-base font-semibold md:text-lg dark:text-neutral-200">
                        András Vágner
                      </h4>
                      <p className="max-w-full truncate text-sm text-gray-500 md:text-base dark:text-neutral-400">
                        Founder, Software Engineer
                      </p>
                    </Stack>
                    <Stack direction="horizontal">
                      <Button
                        className="size-9 bg-gray-100 p-none hover:bg-gray-200 active:bg-gray-300 dark:bg-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:active:bg-neutral-500"
                        size="custom"
                        as="link"
                        href="https://www.linkedin.com/in/andras-vagner/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="András Vágner on LinkedIn"
                      >
                        <Icon icon="LinkedinLogo" className="size-5" />
                      </Button>
                      <Button
                        className="size-9 bg-gray-100 p-none hover:bg-gray-200 active:bg-gray-300 dark:bg-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:active:bg-neutral-500"
                        size="custom"
                        as="link"
                        href="https://www.instagram.com/balazs.andras/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="András Vágner on Instagram"
                      >
                        <Icon icon="InstagramLogo" className="size-5" />
                      </Button>
                      <Button
                        className="size-9 bg-gray-100 p-none hover:bg-gray-200 active:bg-gray-300 dark:bg-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:active:bg-neutral-500"
                        size="custom"
                        as="link"
                        href="https://t.me/slimtiexxx"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="András Vágner on Telegram"
                      >
                        <Icon icon="TelegramLogo" className="size-5" />
                      </Button>
                    </Stack>
                  </div>
                </header>
                <div className="prose prose-stone max-w-none text-sm leading-relaxed dark:prose-invert md:text-base">
                  <p>Hi! I’m András, creator of Football Genie. 👋</p>
                  <p>
                    I’m hopeless at playing football, but I know my way around
                    tech! After 11 years as software engineer, I’ve built a tool
                    that <strong>actually helps</strong> you make smarter
                    football decisions.
                  </p>
                  <p>Let’s win together! 🚀</p>
                </div>
              </article>
              <Stack className="w-full" spacing="lg">
                <Stack
                  className="w-full rounded-lg border border-gray-200 p-3.5 md:p-5 dark:border-neutral-700 dark:bg-neutral-800"
                  direction="horizontal"
                  align="center"
                  spacing="md"
                >
                  <div className="flex w-full items-center gap-sm md:gap-md">
                    <div className="rounded-full bg-primary-500/10 p-4">
                      <Icon
                        icon="EnvelopeSimple"
                        className="size-5 text-primary md:size-6"
                        aria-label="Envelope icon representing email address"
                      />
                    </div>
                    <Stack spacing="none">
                      <p className="font-semibold dark:text-neutral-200">
                        E-mail
                      </p>
                      <p className="text-sm text-gray-500 md:text-base dark:text-neutral-400">
                        {email}
                      </p>
                    </Stack>
                  </div>
                  <Button
                    className={cn(
                      "relative size-12 flex-none will-change-transform hover:bg-gray-100 active:bg-gray-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:active:bg-neutral-600",
                      {
                        "bg-primary-500/10 hover:bg-primary-500/10 active:bg-primary-500/10 dark:bg-primary-500/10 dark:hover:bg-primary-500/10 dark:active:bg-primary-500/10":
                          emailCopied,
                      },
                    )}
                    size="custom"
                    type="button"
                    onClick={handleCopyToClipboard}
                    aria-label="Copy email address to clipboard"
                  >
                    <Icon
                      icon="Copy"
                      className={cn(
                        "absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2 text-gray-500 transition-all will-change-transform dark:text-neutral-400",
                        {
                          "translate-x-14": emailCopied,
                        },
                      )}
                      aria-label="Copy icon representing copying email address"
                    />
                    <Icon
                      icon="Check"
                      className={cn(
                        "absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2 text-gray-500 transition-all will-change-transform dark:text-neutral-400",
                        {
                          "-translate-x-14": !emailCopied,
                        },
                      )}
                      aria-label="Check icon representing email copied"
                    />
                    <span className="sr-only">
                      {emailCopied ? "Email copied" : "Copy email"}
                    </span>
                  </Button>
                </Stack>
                <a
                  href="https://www.instagram.com/geniebets/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group w-full"
                  aria-label="Visit our Instagram page @geniebets"
                >
                  <div className="flex w-full items-center gap-sm rounded-lg border border-gray-200 p-3.5 md:gap-md md:p-5 dark:border-neutral-700 dark:bg-neutral-800">
                    <div className="rounded-full bg-primary-500/10 p-4 transition-all group-hover:bg-primary-500/20 group-active:bg-primary-500/30">
                      <Icon
                        icon="InstagramLogo"
                        className="size-5 text-primary md:size-6"
                        aria-label="Instagram logo representing our Instagram page"
                      />
                    </div>
                    <Stack spacing="none">
                      <p className="font-semibold dark:text-neutral-200">
                        Instagram
                      </p>
                      <p className="text-sm text-gray-500 md:text-base dark:text-neutral-400">
                        @geniebets
                      </p>
                    </Stack>
                  </div>
                </a>
                <address className="flex w-full items-center gap-sm rounded-lg border border-gray-200 p-3.5 md:gap-md md:p-5 dark:border-neutral-700 dark:bg-neutral-800">
                  <div className="rounded-full bg-primary-500/10 p-4">
                    <Icon
                      icon="Buildings"
                      className="size-5 text-primary md:size-6"
                      aria-hidden="true"
                      aria-label="Buildings icon representing address"
                    />
                  </div>
                  <Stack spacing="none">
                    <p className="font-semibold dark:text-neutral-200">
                      Address
                    </p>
                    <p className="text-sm text-gray-500 md:text-base dark:text-neutral-400">
                      Hungary, 2621, Verőce, Szeles utca 7380
                    </p>
                  </Stack>
                </address>
              </Stack>
            </div>
          </div>
          <Stack
            direction="horizontal"
            className="w-full flex-1 justify-center"
          >
            <ContactForm />
          </Stack>
        </div>
      </Container>
      <div aria-live="polite" className="sr-only">
        {emailCopied && "Email address copied to clipboard."}
      </div>
    </section>
  );
};
