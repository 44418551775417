"use client";

import NextImage, { ImageProps as NextImageProps } from "next/image";
import { FC, useEffect, useState } from "react";
import { useTheme } from "next-themes";

type ImageProps = NextImageProps & {
  darkSrc?: NextImageProps["src"];
};

export const Image: FC<ImageProps> = ({ darkSrc, src, ...rest }) => {
  const { resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  if (!mounted) return null;

  const resolvedSrc = resolvedTheme === "dark" && darkSrc ? darkSrc : src;

  return <NextImage src={resolvedSrc} {...rest} />;
};
