"use client";

import React, { useMemo, useRef, useState } from "react";
import { Stack } from "@ui/layout";
import { Button, Icon } from "@ui/elements";
import { Input } from "@components/forms/Input/Input";
import { TextArea } from "@components/forms/TextArea/TextArea";
import { useForm, Controller } from "react-hook-form";
import { useContact } from "@api";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from "next-themes";
import { GetInTouchHeader } from "@components/Contact/GetInTouchHeader";

type FormData = {
  name: string;
  email: string;
  message: string;
  recaptchaToken: string;
};

export const ContactForm = () => {
  const { resolvedTheme } = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();
  const {
    mutateAsync: sendContact,
    isPending: sendContactLoading,
    isError,
    error,
  } = useContact();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const captchaTheme = useMemo(() => {
    if (resolvedTheme === "dark") {
      return "dark";
    }

    if (resolvedTheme === "light") {
      return "light";
    }

    return undefined;
  }, [resolvedTheme]);

  const onSubmit = async (data: FormData) => {
    const recaptchaToken = await recaptchaRef.current?.executeAsync();

    recaptchaRef.current?.reset();

    data.recaptchaToken = recaptchaToken || "";

    const cleanedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) {
        acc[key as keyof FormData] = value;
      }
      return acc;
    }, {} as FormData);

    await sendContact({ data: cleanedData });

    setShowSuccess(true);

    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);

    reset();
  };

  return (
    <Stack className="w-full max-w-3xl xl:max-w-xl" spacing="xxl">
      <GetInTouchHeader className="hidden xl:flex" />
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Stack spacing="xl">
          <Stack className="w-full" spacing="lg">
            <Stack className="w-full" spacing="xs">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <Input
                    label="Name"
                    type="text"
                    id="name"
                    autoComplete="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <span role="alert" className="text-sm text-red-400">
                  {errors.name.message}
                </span>
              )}
            </Stack>

            <Stack className="w-full" spacing="xs">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <Input
                    label="E-mail"
                    type="email"
                    id="email"
                    autoComplete="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <span role="alert" className="text-sm text-red-400">
                  {errors.email.message}
                </span>
              )}
            </Stack>

            <Stack className="w-full" spacing="xs">
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{ required: "Message is required" }}
                render={({ field }) => (
                  <TextArea
                    label="Message"
                    rows={5}
                    id="message"
                    aria-invalid={errors.message ? "true" : "false"}
                    {...field}
                  />
                )}
              />
              {errors.message && (
                <span role="alert" className="text-sm text-red-400">
                  {errors.message.message}
                </span>
              )}
            </Stack>

            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""}
              size="invisible"
              badge="bottomleft"
              key={`captcha-${captchaTheme}`}
              theme={captchaTheme}
              ref={recaptchaRef}
            />
          </Stack>
          <Stack spacing="xl" className="w-full">
            {isError && (
              <div
                className="w-full max-w-xl rounded-lg bg-red-500/5 px-4 py-4"
                role="alert"
                aria-live="assertive"
              >
                <Stack>
                  <Stack direction="horizontal" align="center" spacing="sm">
                    <Icon icon="Warning" className="size-6 text-red-500" />
                    <p className="font-semibold text-red-500">
                      {error?.message || "Unexpected error"}
                    </p>
                  </Stack>
                  <p className="pl-9 text-sm leading-relaxed text-red-500">
                    An unexpected error occurred. Please try again later or
                    contact us directly at{" "}
                    <span className="underline">info@football-genie.com</span>.
                  </p>
                </Stack>
              </div>
            )}
            {showSuccess && (
              <div
                className="w-full max-w-xl rounded-lg bg-green-500/10 px-4 py-4 dark:bg-green-500/5"
                role="status"
                aria-live="polite"
              >
                <Stack>
                  <Stack direction="horizontal" align="center" spacing="sm">
                    <Icon
                      icon="CheckCircle"
                      className="size-6 text-green-500"
                    />
                    <p className="font-semibold text-green-500">
                      Your message has been sent!
                    </p>
                  </Stack>
                  <p className="pl-9 text-sm leading-relaxed text-green-500">
                    Thank you for contacting us!
                    <br />
                    We will get back to you as soon as possible.
                  </p>
                </Stack>
              </div>
            )}
            <Stack
              direction="horizontal"
              className="w-full justify-between"
              align="center"
            >
              <p className="max-w-80 text-sm leading-relaxed text-gray-500 dark:text-neutral-400">
                By contacting us, you agree to our{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className="text-primary underline"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="text-primary underline"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <Button
                color="primary"
                size="large"
                className="w-40"
                type="submit"
                isLoading={sendContactLoading}
                aria-label="Send contact form"
              >
                <span>Send</span>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
