import { Stack } from "@ui/layout";
import { useFixtureContext } from "@components/FixtureCard/context";
import { Icon } from "@ui/elements";

export const DoDont = () => {
  const {
    source: { data },
    fixture,
  } = useFixtureContext();

  return (
    <Stack spacing="xl">
      <h4 className="sr-only">Do and Don&#39;t Recommendations</h4>
      <Stack>
        <Stack direction="horizontal" spacing="xs">
          <Icon
            icon="ThumbsUp"
            weight="duotone"
            className="size-6 text-green-500"
          />
          <h4 className="bg mid text font-semibold dark:text-neutral-300">
            Do
          </h4>
        </Stack>
        {data?.data.recommendations.betting_recommendations.map((item) => (
          <Stack key={`${item.title}-${fixture.fixture.id}`} spacing="xxs">
            <Stack direction="horizontal" align="center" spacing="xs">
              <div className="flex size-4 items-center justify-center rounded-full bg-green-400/30">
                <div className="size-2 rounded-full bg-green-400" />
              </div>
              <h5 className="text-sm font-semibold dark:text-neutral-300">
                {item.title}
              </h5>
            </Stack>
            <p className="text-sm leading-relaxed text-gray-500 dark:text-neutral-400">
              {item.short_reasoning}
            </p>
          </Stack>
        ))}
      </Stack>
      <Stack>
        <Stack direction="horizontal" spacing="xs">
          <Icon
            icon="ThumbsDown"
            weight="duotone"
            className="size-6 text-red-500"
          />
          <h4 className="bg mid text font-semibold dark:text-neutral-300">
            Don&#39;t
          </h4>
        </Stack>
        {data?.data.recommendations.things_to_avoid.map((item) => (
          <Stack key={`${item.title}-${fixture.fixture.id}`} spacing="xxs">
            <Stack direction="horizontal" align="center" spacing="xs">
              <div className="flex size-4 items-center justify-center rounded-full bg-red-400/30">
                <div className="size-2 rounded-full bg-red-400" />
              </div>
              <h5 className="text-sm font-semibold dark:text-neutral-300">
                {item.title}
              </h5>
            </Stack>
            <p className="text-sm leading-relaxed text-gray-500 dark:text-neutral-400">
              {item.short_reasoning}
            </p>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
