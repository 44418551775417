import { Stack } from "@ui/layout";
import { Button, Tag, Title } from "@ui/elements";
import Image, { StaticImageData } from "next/image";
import fire from "./fire.png";
import target from "./target.png";
import heart from "./heart.png";
import bulb from "./bulb.png";
import gym from "./gym.png";
import chess from "./chess.png";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Container } from "@components/Container/Container";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

type CardProps = {
  image: StaticImageData;
  imageAlt: string;
  title: string;
  description: string;
};

const firstColumnCards: CardProps[] = [
  {
    image: target,
    imageAlt: "Target icon representing Correct Score Prediction",
    title: "Correct Score Prediction",
    description:
      "Receive precise score forecasts for every football match to enhance your betting strategy.",
  },
  {
    image: fire,
    imageAlt: "Fire icon representing Motivation Analysis",
    title: "Motivation Analysis",
    description:
      "Analyze team motivations to understand their drive and potential performance before the match.",
  },
  {
    image: chess,
    imageAlt: "Chess icon representing Strategy Comparison",
    title: "Strategy Comparison",
    description:
      "Compare team strategies in-depth to predict match dynamics and identify betting opportunities.",
  },
];

const secondColumnCards: CardProps[] = [
  {
    image: heart,
    imageAlt: "Heart icon representing Injury Impact",
    title: "Injury Impact",
    description:
      "Assess how player injuries affect team performance and match outcomes.",
  },
  {
    image: bulb,
    imageAlt: "Bulb icon representing Match Preview",
    title: "Match Preview",
    description:
      "Get comprehensive previews of upcoming matches, including key factors and expected dynamics.",
  },
  {
    image: gym,
    imageAlt: "Gym icon representing Recent Form",
    title: "Recent Form",
    description:
      "Evaluate the recent performance of teams to gauge their current form and predict future results.",
  },
];

const MatchAnalysisCard: FC<CardProps & { id: string }> = ({
  image,
  imageAlt,
  title,
  description,
  id,
}) => {
  return (
    <article
      className="flex h-full w-80 flex-none flex-col gap-md rounded-2xl bg-white p-7 shadow-sm md:w-96 md:p-xxl lg:h-auto dark:bg-neutral-700"
      aria-labelledby={`card-title-${title.toLowerCase().split(" ").join("-")}-${id}`}
    >
      <Image
        src={image}
        className="size-20 drop-shadow-lg md:size-24"
        alt={imageAlt}
        loading="lazy"
      />
      <Title
        styleAs="h3"
        as="h4"
        className="whitespace-break-spaces lg:whitespace-nowrap"
        id={`card-title-${title.toLowerCase().split(" ").join("-")}-${id}`}
      >
        {title}
      </Title>
      <p className="text-sm md:text-base dark:text-neutral-300">
        {description}
      </p>
    </article>
  );
};

export const MatchAnalysis = () => {
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");

  return (
    <section
      aria-labelledby="match-analysis-section-title"
      className="flex max-w-[100vw] flex-col gap-xxl overflow-hidden bg-gray-100 px-6 py-24 dark:bg-neutral-800"
    >
      <Container>
        <div className="max-w-8xl mx-auto flex flex-col items-center gap-xxl lg:flex-row">
          <Stack
            spacing="xl"
            className="flex-none lg:w-128 xl:w-auto xl:max-w-lg"
          >
            <header
              className="flex flex-col items-start gap-md"
              data-aos="fade-up"
            >
              <Tag size="md" variant="neutral" className="dark:bg-neutral-700">
                Match Analysis
              </Tag>
              <Title styleAs="h1" as="h2" id="match-analysis-section-title">
                Advanced Analysis
              </Title>
              <Title type="subtitle" styleAs="h4" as="h3" color="tertiary">
                Unlock in-depth analysis with AI predictions, team motivations,
                injury impacts, and match dynamics to maximize your sports
                betting profits.
              </Title>
            </header>
            <Button
              color="primary"
              size="medium"
              data-aos="zoom-in"
              data-aos-delay="200"
              aria-label="Get Started with Expert Betting Tips"
              as="link"
              href={dashboardLink}
              onClick={() =>
                sendGTMEvent({
                  event: "cta_click",
                  value: "match_analysis",
                  ...getUtmParams(),
                })
              }
            >
              Try for Free
            </Button>
          </Stack>
          <div className="flex max-w-full flex-col justify-center gap-lg lg:flex-1 lg:flex-row">
            {/* First Column */}
            <div
              className="relative -mr-6 flex flex-col gap-lg overflow-hidden lg:mr-none lg:mt-8 lg:h-[40rem]"
              aria-label="Accurate Score Predictions, Team Motivation Insights, Comprehensive Strategy Comparison"
            >
              <Swiper
                slidesPerView="auto"
                direction="horizontal"
                className="max-w-full"
                loop
                spaceBetween={17.5}
                grabCursor
                modules={[Autoplay]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  1024: { direction: "vertical", centeredSlides: true },
                }}
              >
                {[...firstColumnCards, ...firstColumnCards].map((e, index) => (
                  <SwiperSlide
                    className="!h-auto !w-fit"
                    key={`match-analysis-${e.title}-${index}`}
                  >
                    <MatchAnalysisCard id={`column-1-${index}`} {...e} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* Desktop Overlays */}
              <div className="pointer-events-none absolute left-0 top-0 z-10 hidden h-24 w-full bg-gradient-to-b from-gray-100 to-transparent lg:block dark:from-neutral-800"></div>
              <div className="pointer-events-none absolute bottom-0 left-0 z-10 hidden h-24 w-full bg-gradient-to-t from-gray-100 to-transparent lg:block dark:from-neutral-800"></div>
            </div>
            {/* Second Column */}
            <div
              className="relative -mr-6 flex flex-col gap-lg overflow-hidden lg:mr-none lg:mt-8 lg:h-[40rem]"
              aria-label="Injury Impact Analysis, Detailed Match Previews, Recent Form Evaluation"
            >
              <Swiper
                slidesPerView="auto"
                direction="horizontal"
                className="max-w-full"
                loop
                spaceBetween={17.5}
                grabCursor
                modules={[Autoplay]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                  reverseDirection: true,
                }}
                breakpoints={{
                  1024: { direction: "vertical", centeredSlides: true },
                }}
              >
                {[...secondColumnCards, ...secondColumnCards].map(
                  (e, index) => (
                    <SwiperSlide
                      className="!h-auto !w-fit"
                      key={`match-analysis-${e.title}-${index}`}
                    >
                      <MatchAnalysisCard id={`column-2-${index}`} {...e} />
                    </SwiperSlide>
                  ),
                )}
              </Swiper>
              {/* Desktop Overlays */}
              <div className="pointer-events-none absolute left-0 top-0 z-10 hidden h-24 w-full bg-gradient-to-b from-gray-100 to-transparent lg:block dark:from-neutral-800"></div>
              <div className="pointer-events-none absolute bottom-0 left-0 z-10 hidden h-24 w-full bg-gradient-to-t from-gray-100 to-transparent lg:block dark:from-neutral-800"></div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
