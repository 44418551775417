import { Stack } from "@ui/layout";
import { Button, Tag, Title } from "@ui/elements";
import React, { HTMLAttributes } from "react";
import Image, { StaticImageData } from "next/image";
import rocket from "./rocket.png";
import lab from "./lab.png";
import chatBubble from "./chat-bubble.png";
import { cn } from "@/lib/utils";
import { Container } from "@components/Container/Container";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams } from "@utils";

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  icon: StaticImageData;
  alt: string;
  title: string;
  description: string;
  bgColorLight: string;
  bgColorDark: string;
}

const Card: React.FC<CardProps> = ({
  icon,
  alt,
  title,
  description,
  bgColorLight,
  bgColorDark,
  className,
  ...rest
}) => {
  return (
    <article
      className={cn(
        "group relative overflow-hidden rounded-lg border border-gray-200 p-7 md:p-10 2xl:p-14 dark:border-neutral-700 dark:bg-neutral-800",
        className,
      )}
      {...rest}
    >
      {/* Background Circles */}
      <div className="absolute inset-0 hidden dark:block" aria-hidden="true">
        <div
          className="absolute size-40 -translate-x-1/2 rounded-full opacity-60 blur-3xl filter transition-transform duration-500 xl:group-hover:translate-y-96 dark:opacity-80"
          style={{
            backgroundColor: bgColorDark,
          }}
        />
        <div
          className="absolute bottom-0 right-0 size-40 translate-x-1/2 rounded-full opacity-60 blur-3xl filter transition-transform duration-500 xl:group-hover:-translate-y-96 dark:opacity-80"
          style={{
            backgroundColor: bgColorDark,
          }}
        />
      </div>
      <div className="absolute inset-0 dark:hidden" aria-hidden="true">
        <div
          className="absolute size-40 -translate-x-1/2 rounded-full opacity-60 blur-3xl filter transition-transform duration-500 xl:group-hover:translate-y-96 dark:opacity-80"
          style={{
            backgroundColor: bgColorLight,
          }}
        />
        <div
          className="absolute bottom-0 right-0 size-40 translate-x-1/2 rounded-full opacity-60 blur-3xl filter transition-transform duration-500 xl:group-hover:-translate-y-96 dark:opacity-80"
          style={{
            backgroundColor: bgColorLight,
          }}
        />
      </div>
      {/* Content */}
      <div className="relative z-10 flex flex-col gap-xxs md:gap-md">
        <Image
          src={icon}
          className="size-32 md:size-40"
          alt={alt}
          loading="lazy"
        />
        <h4 className="mt-4 text-xl font-semibold text-gray-800 md:text-2xl dark:text-gray-100">
          {title}
        </h4>
        <p className="mt-2 text-sm leading-relaxed tracking-wide text-gray-600 md:text-base dark:text-neutral-300">
          {description}
        </p>
      </div>
    </article>
  );
};

export const AboutUs: React.FC = () => {
  return (
    <section
      className="flex flex-col items-start gap-md px-6 py-14 md:py-24"
      aria-labelledby="about-us-heading"
    >
      <Container>
        <Stack spacing="xxxl" className="w-full">
          <header
            data-aos="fade-up"
            className="flex w-full flex-col items-start gap-md"
          >
            <Tag size="md" variant="neutral" as="p">
              About Us
            </Tag>
            <Stack spacing="xxl" className="w-full">
              <Stack spacing="xs" className="w-full max-w-4xl justify-between">
                <Title as="h2" styleAs="h1" id="about-us-heading">
                  We are shaping the future of{" "}
                  <strong className="text-primary">Football Analysis</strong>
                </Title>
                <Title
                  as="h3"
                  type="subtitle"
                  color="tertiary"
                  className="font-light leading-relaxed tracking-wide"
                >
                  Our passion for football and cutting-edge technology fuels
                  every feature we create. Discover the core values that drive
                  us.
                </Title>
              </Stack>
            </Stack>
          </header>
          <div className="grid grid-cols-1 gap-xl md:grid-cols-2 xl:grid-cols-3 2xl:gap-xxl">
            <Card
              icon={rocket}
              alt="Rocket icon symbolizing startup spirit in football betting analysis"
              title="Startup Spirit"
              description="As a small, independent startup, we focus entirely on what matters: delivering exceptional insights straight to you."
              bgColorLight="rgba(255, 99, 71, 0.3)"
              bgColorDark="rgba(255, 99, 71, 0.8)"
            />
            <Card
              icon={lab}
              alt="Laboratory icon representing expertise in technology for football analysis"
              title="Experts in Technology"
              description="We harness the power of AI to revolutionize football analysis, offering fans and bettors unparalleled tools to stay ahead."
              bgColorLight="rgba(249, 20, 78, 0.3)"
              bgColorDark="rgba(249, 20, 78, 0.8)"
            />
            <Card
              icon={chatBubble}
              alt="Chat bubbles illustrating community-driven approach in football analysis"
              title="Community-Driven"
              description="Our platform evolves with your feedback at its heart. Join us, and let’s innovate the future of football analysis together."
              bgColorLight="rgba(70, 130, 180, 0.3)"
              bgColorDark="rgba(70, 130, 180, 0.8)"
              className="md:col-span-2 xl:col-span-1"
            />
          </div>
          <Button
            color="primary"
            size="medium"
            href="#contact"
            as="link"
            aria-label="Get in touch with us"
            onClick={() =>
              sendGTMEvent({
                event: "link_click",
                value: "about_us_contact",
                ...getUtmParams(),
              })
            }
          >
            Get in Touch
          </Button>
        </Stack>
      </Container>
    </section>
  );
};
