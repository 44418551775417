import { Button, Icon, Icons } from "@ui/elements";
import { cn } from "@/lib/utils";
import { useTheme } from "next-themes";
import { useEffect, useMemo, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export const ThemeSwitcher = () => {
  const { theme, resolvedTheme, setTheme } = useTheme();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [mounted, setMounted] = useState(false);

  // Refs for focus trapping
  const firstFocusableRef = useRef<HTMLButtonElement>(null);
  const lastFocusableRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setMounted(true);

    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownOpen &&
        contentRef.current &&
        !contentRef.current.contains(e.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setDropdownOpen(false);
        buttonRef.current?.focus();
      }

      if (e.key === "Tab" && dropdownOpen) {
        const focusableElements =
          contentRef.current?.querySelectorAll<HTMLButtonElement>(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          );
        if (focusableElements && focusableElements.length > 0) {
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (!e.shiftKey && document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }

          if (e.shiftKey && document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        }
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      // Optionally set focus to the first focusable element
      firstFocusableRef.current?.focus();
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropdownOpen]);

  const themeButtonIcon: Icons = useMemo(() => {
    switch (resolvedTheme) {
      case "light":
        return "Sun";
      case "dark":
        return "Moon";
      default:
        return "Sun";
    }
  }, [resolvedTheme]);

  const handleDropdownItemClick = (
    selectedTheme: "light" | "dark" | "system",
  ) => {
    setTheme(selectedTheme);
    setDropdownOpen(false);
  };

  const dropdownVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.2 },
    },
    closed: {
      opacity: 0,
      y: -10,
      transition: { duration: 0.2 },
    },
  };

  if (!mounted) {
    return null;
  }

  return (
    <div className="relative">
      <Button
        ref={buttonRef}
        size="custom"
        onClick={() => setDropdownOpen((prev) => !prev)}
        className={cn(
          "size-10 rounded-full bg-white shadow-none hover:bg-neutral-100 lg:size-14 dark:bg-neutral-800 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:active:bg-neutral-600",
          {
            "bg-neutral-100 dark:bg-neutral-700": dropdownOpen,
          },
        )}
        aria-haspopup="true"
        aria-expanded={dropdownOpen}
        aria-label="Toggle theme switcher"
      >
        <Icon icon={themeButtonIcon} className="size-5" weight="light" />
      </Button>
      <AnimatePresence>
        {dropdownOpen && (
          <motion.div
            key="dropdown"
            ref={contentRef}
            initial="closed"
            animate="open"
            exit="closed"
            variants={dropdownVariants}
            className="absolute right-0 top-full mt-2 flex w-40 flex-col gap-1 rounded-lg border border-gray-200 bg-white p-2 shadow-sm dark:border-neutral-600 dark:bg-neutral-700 dark:shadow-none"
            role="menu"
            aria-label="Theme options"
          >
            <ul className="flex flex-col">
              <li>
                <button
                  ref={firstFocusableRef}
                  onClick={() => handleDropdownItemClick("light")}
                  className={cn(
                    "flex w-full cursor-pointer items-center gap-3 rounded-sm px-3 py-2 text-sm text-gray-600 transition-all hover:bg-yellow-300/20 active:bg-yellow-300/30 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:active:bg-neutral-500",
                    {
                      "bg-yellow-300/20 text-yellow-800 hover:bg-yellow-300/30 active:bg-yellow-300/40":
                        theme === "light",
                    },
                  )}
                  role="menuitem"
                >
                  <Icon
                    icon="Sun"
                    className="size-5"
                    weight={theme === "light" ? "duotone" : "light"}
                    aria-hidden="true"
                  />
                  <span>Light</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleDropdownItemClick("dark")}
                  className={cn(
                    "flex w-full cursor-pointer items-center gap-3 rounded-sm px-3 py-2 text-sm text-gray-600 transition-all hover:bg-gray-100 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:active:bg-neutral-500",
                    {
                      "bg-neutral-800/50 text-sky-800 dark:text-neutral-200 dark:hover:bg-neutral-800/50 dark:active:bg-neutral-800/50":
                        theme === "dark",
                    },
                  )}
                  role="menuitem"
                >
                  <Icon
                    icon="Moon"
                    className="size-5"
                    weight={theme === "dark" ? "duotone" : "light"}
                    aria-hidden="true"
                  />
                  <span>Dark</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleDropdownItemClick("system")}
                  className={cn(
                    "flex w-full cursor-pointer items-center gap-3 rounded-sm px-3 py-2 text-sm text-gray-600 transition-all hover:bg-sky-400/20 active:bg-sky-400/30 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:active:bg-neutral-500",
                    {
                      "bg-sky-400/20 text-sky-900 hover:bg-sky-400/30 active:bg-sky-400/40 dark:hover:bg-sky-400/30 dark:active:bg-sky-400/40":
                        theme === "system",
                    },
                  )}
                  role="menuitem"
                  ref={lastFocusableRef}
                >
                  <Icon
                    icon="Monitor"
                    className="size-5"
                    weight={theme === "system" ? "duotone" : "light"}
                    aria-hidden="true"
                  />
                  <span>System</span>
                </button>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
