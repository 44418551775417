import { Stack } from "@ui/layout";
import { ThemeSwitcher } from "@components/ThemeSwitcher/ThemeSwitcher";
import { Button, Icon } from "@ui/elements";
import Link from "next/link";
import { FC, useEffect, useRef } from "react";
import { cn } from "@/lib/utils";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

type MobileMenuProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const MobileMenu: FC<MobileMenuProps> = ({ open, setOpen }) => {
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const mobileMenuButtonRef = useRef<HTMLButtonElement>(null);
  const firstFocusableRef = useRef<HTMLAnchorElement>(null);
  const lastFocusableRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(e.target as Node) &&
        mobileMenuButtonRef.current &&
        !mobileMenuButtonRef.current.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
        mobileMenuButtonRef.current?.focus();
      }

      if (e.key === "Tab" && open) {
        const focusableElements =
          mobileMenuRef.current?.querySelectorAll<HTMLElement>(
            'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])',
          );
        if (focusableElements && focusableElements.length > 0) {
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (!e.shiftKey && document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }

          if (e.shiftKey && document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        }
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside, {
        passive: true,
      });
      document.addEventListener("keydown", handleKeyDown);
      // Optionally set focus to the first focusable element
      firstFocusableRef.current?.focus();
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [mobileMenuRef, mobileMenuButtonRef, setOpen, open]);

  return (
    <>
      {/* Mobile Menu Button */}
      <Stack className="lg:hidden" direction="horizontal" spacing="xxs">
        <ThemeSwitcher />
        <Button
          size="custom"
          className="relative mr-2 size-10 overflow-hidden rounded-full bg-white shadow-none hover:bg-neutral-100 lg:size-14 dark:bg-neutral-800 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:active:bg-neutral-600"
          onClick={() => setOpen(!open)}
          ref={mobileMenuButtonRef}
          aria-controls="mobile-menu"
          aria-expanded={open}
          aria-label="Toggle mobile menu"
        >
          <Icon
            icon="X"
            className={cn(
              "absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2 transition-all",
              {
                "translate-x-14": !open,
              },
            )}
          />
          <Icon
            icon="List"
            className={cn(
              "absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2 transition-all",
              {
                "-translate-x-14": open,
              },
            )}
          />
        </Button>
      </Stack>

      <nav
        aria-label="Mobile navigation"
        id="mobile-menu"
        ref={mobileMenuRef}
        className={cn(
          "absolute left-0 top-full mt-3 w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all focus:outline-none lg:hidden dark:bg-neutral-800 dark:shadow-none dark:ring-neutral-700",
          {
            "pointer-events-none translate-x-40 opacity-0": !open,
          },
        )}
      >
        <ul className="flex flex-col items-center py-3">
          <li className="w-full text-center">
            <Link
              className="block min-h-12 px-4 py-2 text-base leading-9 text-gray-700 hover:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-700"
              href="#how-it-works"
              onClick={() => {
                setOpen(false);
                sendGTMEvent({
                  event: "link_click",
                  value: "how_it_works",
                  ...getUtmParams(),
                });
              }}
              aria-label="Learn How It Works"
            >
              How it works
            </Link>
          </li>
          <li className="w-full text-center">
            <Link
              ref={firstFocusableRef}
              className="block min-h-12 px-4 py-2 text-base leading-9 text-gray-700 hover:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-700"
              href="#features"
              onClick={() => {
                setOpen(false);
                sendGTMEvent({
                  event: "link_click",
                  value: "features",
                  ...getUtmParams(),
                });
              }}
              aria-label="Explore Features"
            >
              Features
            </Link>
          </li>
          <li className="w-full text-center">
            <Link
              className="block min-h-12 px-4 py-2 text-base leading-9 text-gray-700 hover:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-700"
              href="#pricing"
              onClick={() => {
                setOpen(false);
                sendGTMEvent({
                  event: "link_click",
                  value: "pricing",
                  ...getUtmParams(),
                });
              }}
              aria-label="View Pricing Options"
            >
              Pricing
            </Link>
          </li>
          <li className="w-full text-center">
            <Link
              className="block min-h-12 px-4 py-2 text-base leading-9 text-gray-700 hover:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-700"
              href="#contact"
              onClick={() => {
                setOpen(false);
                sendGTMEvent({
                  event: "link_click",
                  value: "contact",
                  ...getUtmParams(),
                });
              }}
              aria-label="Contact Us"
            >
              Contact
            </Link>
          </li>
          {/* Mobile Button */}
          <li className="mt-2 w-full px-3">
            <Button
              color="primary"
              size="medium"
              isRounded
              className="text-md w-full rounded-lg bg-gradient-to-r from-primary-400 to-primary-600 px-11 py-3.5 font-semibold text-white dark:text-gray-900"
              as="link"
              title="Get AI-driven football predictions and analysis"
              aria-label="Get AI-driven football predictions and analysis"
              href={dashboardLink}
              ref={lastFocusableRef}
              onClick={() =>
                sendGTMEvent({
                  event: "cta_click",
                  value: "navbar_predictions",
                  ...getUtmParams(),
                })
              }
            >
              <Stack direction="horizontal" spacing="xs" align="center">
                <span>Predictions</span>
                <Icon
                  icon="CaretRight"
                  weight="bold"
                  className="-mr-3 size-6"
                />
              </Stack>
            </Button>
          </li>
        </ul>
      </nav>
    </>
  );
};
