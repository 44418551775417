import { useState, useEffect } from "react";

type DeviceType = "mobile" | "tablet" | "desktop";

const MOBILE_MAX = 639;
const TABLET_MAX = 1023;

export function useDeviceType(): DeviceType {
  const [deviceType, setDeviceType] = useState<DeviceType>("mobile");

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= MOBILE_MAX) {
        setDeviceType("mobile");
      } else if (width <= TABLET_MAX) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return deviceType;
}
