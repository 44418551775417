import { Stack } from "@ui/layout";
import { StatusColor, statusColorClasses } from "@utils";
import { FC } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import CountUp from "react-countup";

type ProgressBarProps = {
  values: number[];
  colorRule: (value: number) => StatusColor;
  prefix?: string;
  label: string;
  leftLabel?: string;
  rightLabel?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({
  values,
  colorRule,
  prefix,
  label,
  leftLabel,
  rightLabel,
  ...rest
}) => {
  const [leftValue, rightValue] = values;

  const customEase = [0.42, 0, 0.58, 1];

  return (
    <Stack className="w-full" spacing="xxs" role="group" {...rest}>
      <Stack direction="horizontal" className="w-full" align="center">
        <p className="font-semibold dark:text-neutral-200">
          <span className="sr-only">{leftLabel}</span>
          <CountUp end={leftValue} duration={1.5} />
          {prefix && (
            <span className="text-xs font-normal text-gray-400">{prefix}</span>
          )}
        </p>
        <p className="w-full text-center text-sm dark:text-neutral-200">
          {label}
        </p>
        <Stack
          align="baseline"
          direction="horizontal"
          spacing="none"
          className="dark:text-neutral-200"
        >
          <p className="font-semibold">
            <span className="sr-only">{rightLabel}</span>
            <CountUp end={rightValue} duration={1.5} />
          </p>
          {prefix && <p className="text-xs text-gray-400">{prefix}</p>}
        </Stack>
      </Stack>
      <Stack
        direction="horizontal"
        spacing="xs"
        className="h-2.5 w-full"
        align="stretch"
      >
        <div className="relative flex w-full overflow-hidden rounded-bl-lg rounded-tl-lg bg-gray-200 dark:bg-neutral-700">
          <motion.div
            className={classNames(
              "absolute right-0 h-full rounded-bl-lg rounded-tl-lg",
              statusColorClasses[colorRule(leftValue)].mid,
            )}
            role="progressbar"
            aria-valuenow={leftValue}
            aria-valuemin={0}
            aria-valuemax={10}
            aria-label={`${leftLabel}: ${leftValue} out of 10`}
            initial={{ width: 0, opacity: 0.3 }}
            animate={{ width: `${leftValue * 10}%`, opacity: 1 }}
            transition={{
              duration: 0.5,
              ease: customEase, // Use the custom easing curve
            }}
          />
        </div>
        <div className="relative flex w-full overflow-hidden rounded-br-lg rounded-tr-lg bg-gray-200 dark:bg-neutral-700">
          <motion.div
            className={classNames(
              "absolute left-0 h-full rounded-br-lg rounded-tr-lg",
              statusColorClasses[colorRule(rightValue)].mid,
            )}
            role="progressbar"
            aria-valuenow={rightValue}
            aria-valuemin={0}
            aria-valuemax={10}
            aria-label={`${rightLabel}: ${rightValue} out of 10`}
            initial={{ width: 0, opacity: 0.3 }}
            animate={{ width: `${rightValue * 10}%`, opacity: 1 }}
            transition={{
              duration: 0.5,
              ease: customEase,
            }}
          />
        </div>
      </Stack>
    </Stack>
  );
};
