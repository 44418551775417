import React, { FC, PropsWithChildren } from "react";

import { IconProps as PhosphorIconProps } from "@phosphor-icons/react";

import * as AvailableIcons from "./icon-collection";
export type Icons = keyof typeof AvailableIcons;

type IconProps = PropsWithChildren<{
  icon: Icons;
}> &
  PhosphorIconProps;

export const Icon: FC<IconProps> = ({ icon, weight = "regular", ...props }) => {
  const IconComponent = AvailableIcons[icon];

  return <IconComponent weight={weight} {...props} size={props.size} />;
};
