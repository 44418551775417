import { marked } from "marked";
import { FC } from "react";

type ReadmeContentProps = {
  content?: string;
  className?: string;
};

export const ReadmeContent: FC<ReadmeContentProps> = ({
  content,
  className,
  ...rest
}) => {
  if (!content) return null;

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
      {...rest}
    />
  );
};
