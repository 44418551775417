import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Table,
} from "@tanstack/react-table";
import { Tip, Ratings } from "@api";
import { Stack } from "@ui/layout";
import classNames from "classnames";
import { Button, Icon, Tag } from "@ui/elements";
import { StatusColor } from "@utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/Tooltip/Tooltip";

const TagVariant: Record<Ratings, StatusColor> = {
  safe: "success",
  "super safe": "success",
  value: "info",
  risky: "danger",
};

interface TipTableProps {
  data: Tip[];
}

const TipTable: React.FC<TipTableProps> = ({ data }) => {
  const columns = React.useMemo<ColumnDef<Tip, string | number>[]>(
    () => [
      {
        accessorFn: (row) => row.bet_amount,
        id: "reasoning",
        header: "",
        cell: (info) => {
          const row = info.row.original;

          return (
            <Tooltip placement="bottom-start">
              <TooltipTrigger>
                <Button
                  size="custom"
                  className="-ml-1 -mr-3 size-9 w-9 max-w-9 bg-gray-100 p-0 hover:bg-gray-200 active:scale-95 active:bg-gray-300 dark:bg-neutral-700 dark:hover:bg-neutral-600"
                  aria-label={`View reasoning for ${row.category} ${row.name}`}
                >
                  <Icon icon="Lightbulb" className="size-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="prose z-20 max-w-sm rounded border border-gray-300 bg-white px-4 py-3 text-sm dark:prose-invert dark:border-neutral-800 dark:bg-neutral-900">
                <p>{row.reasoning}</p>
              </TooltipContent>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "name",
        header: "",
        cell: (info) => {
          const row = info.row.original;

          return (
            <Stack spacing="none">
              <p>{row.name}</p>
              <p className="max-w-28 truncate text-xs text-gray-500 lg:max-w-36 dark:text-neutral-400">
                {row.category}
              </p>
            </Stack>
          );
        },
      },
      {
        accessorFn: (row) => row.odds,
        id: "odds",
        header: "Odds",
        cell: (info) => {
          const value = info.getValue() as number;

          return value.toFixed(2);
        },
      },
      {
        accessorFn: (row) => row.probability,
        id: "probability",
        header: "Probability",
        cell: (info) => {
          const value = info.getValue() as number;
          const row = info.row.original;

          return (
            <Tag
              size="sm"
              className="px-2.5 py-1.5"
              variant={TagVariant[row.rating]}
            >
              {value.toFixed()}%
            </Tag>
          );
        },
      },
      {
        accessorFn: (row) => row.bet_amount,
        id: "bet_amount",
        header: "Stake",
        cell: (info) => {
          const value = info.getValue() as number;

          return `${value.toFixed()}%`;
        },
      },
      {
        id: "winner",
        header: "Profit/Loss",
        cell: (info) => {
          const row = info.row.original;

          return (
            <p
              className={classNames("font-semibold", {
                "text-green-500": row.winner,
                "text-red-500": !row.winner,
              })}
            >
              {row.winner
                ? `+${(row.bet_amount * (row.odds - 1)).toFixed(2)}%`
                : `-${row.bet_amount.toFixed(2)}%`}
            </p>
          );
        },
      },
    ],
    [],
  );

  // Create the table instance
  const table: Table<Tip> = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getColumnClasses = (columnId: keyof Tip) => {
    if (columnId === "name") {
      return "w-2/3";
    }

    return "w-1/6";
  };

  return (
    <div className="w-full overflow-x-auto rounded-lg">
      <table className="min-w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-2.5 py-2 text-left text-xs font-normal text-gray-400"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="border-t border-gray-200 transition-colors hover:bg-gray-100 dark:border-neutral-700 dark:hover:bg-neutral-700"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={classNames(
                    "px-2.5 py-2 text-sm text-gray-800 dark:text-neutral-300",
                    getColumnClasses(cell.column.id as keyof Tip),
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TipTable;
