"use client";
import { Stack } from "@ui/layout";
import Image, { StaticImageData } from "next/image";
import { Title } from "@ui/elements";
import CountUp from "react-countup";

import dollar from "./dollar.png";
import boy from "./boy.png";
import trophy from "./trophy.png";
import lab from "./lab.png";
import { FC } from "react";
import { useDeviceType } from "@hooks/useDeviceType";

type Stat = {
  img: StaticImageData;
  alt: string;
  end: number;
  prefix: string;
  label: string;
  delay: number;
};

const stats: Stat[] = [
  {
    img: trophy,
    alt: "Trophy representing matches analyzed",
    end: 1800,
    prefix: "+",
    label: "Matches Analyzed",
    delay: 0,
  },
  {
    img: lab,
    alt: "Magic icon symbolizing tips generated",
    end: 24000,
    prefix: "+",
    label: "AI Tips Generated",
    delay: 100,
  },
  {
    img: boy,
    alt: "User icon representing active users",
    end: 200,
    prefix: "+",
    label: "Active Users",
    delay: 200,
  },
  {
    img: dollar,
    alt: "Dollar sign illustrating profits generated",
    end: 10000,
    prefix: "+$",
    label: "Profits Generated",
    delay: 300,
  },
];

const StatCard: FC<Stat> = ({ img, alt, end, prefix, label, delay }) => (
  <Stack
    className="flex-1 rounded-xl bg-white px-sm py-lg pr-xl shadow-sm dark:bg-neutral-700 dark:shadow-none"
    direction="horizontal"
    align="center"
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <Image
      src={img}
      alt={alt}
      className="size-20 drop-shadow-lg"
      loading="lazy"
    />
    <Stack spacing="none">
      <Title styleAs="h2" as="h3">
        <CountUp end={end} prefix={prefix} duration={3} />
      </Title>
      <p className="dark:text-neutral-400">{label}</p>
    </Stack>
  </Stack>
);

export const TrustBooster = () => {
  const device = useDeviceType();

  return (
    <section
      className="flex flex-col gap-md overflow-hidden bg-gray-100 px-6 py-6 md:py-12 dark:bg-neutral-800"
      aria-labelledby="trust-booster-heading"
    >
      <h2 id="trust-booster-heading" className="sr-only">
        AI Key Statistics
      </h2>
      <div className="mx-auto w-full max-w-screen-2xl">
        <div className="grid grid-cols-1 gap-lg md:grid-cols-2 xl:grid-cols-4">
          {stats.map((stat) => (
            <StatCard
              key={stat.label}
              {...stat}
              {...(device !== "desktop" ? { delay: 0 } : {})}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
