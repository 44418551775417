"use client";

import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

type GlowCaptureProps = PropsWithChildren<{ glowColor?: string }>;
export const GlowCapture: React.FC<GlowCaptureProps> = ({
  children,
  glowColor,
}) => {
  const [glowPosition, setGlowPosition] = useState({ x: 0, y: 0 });
  const [glowOpacity, setGlowOpacity] = useState(0);
  const captureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const capture = captureRef.current;

    if (capture) {
      const handleMouseMove = (event: MouseEvent) => {
        // Get the bounding box of the container
        const rect = capture.getBoundingClientRect();

        // Calculate the mouse position relative to the container
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        setGlowPosition({ x, y });
        setGlowOpacity(1);
      };

      const handleMouseLeave = () => {
        setGlowOpacity(0);
      };

      // Add event listeners for mouse move and leave
      capture.addEventListener("mousemove", handleMouseMove);
      capture.addEventListener("mouseleave", handleMouseLeave);

      // Clean up event listeners on component unmount
      return () => {
        capture.removeEventListener("mousemove", handleMouseMove);
        capture.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, []);

  return (
    <div className="glow-capture relative" ref={captureRef}>
      {children}
      <div
        className="radius-lg pointer-events-none absolute inset-0 select-none opacity-[var(--glow-opacity,0)] [mask:radial-gradient(var(--glow-size)_var(--glow-size)_at_var(--glow-x)_var(--glow-y),_var(--glow-color)_1%,_transparent_50%)] [transition:400ms_mask_ease,_0.2s_opacity_ease] [will-change:mask]"
        style={
          {
            "--glow-size": "400px",
            "--glow-x": `${glowPosition.x}px`,
            "--glow-y": `${glowPosition.y}px`,
            "--glow-opacity": glowOpacity,
            "--glow-color": glowColor,
          } as React.CSSProperties
        }
      >
        {children}
      </div>
    </div>
  );
};
