"use client";

import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useTheme } from "next-themes";
import { Button, Tag, Title } from "@ui/elements";
import { Stack } from "@ui/layout";
import DotPattern from "@components/ui/dot-pattern";
import { cn } from "@/lib/utils";
import { Container } from "@components/Container/Container";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

// Dynamic import for Globe
const Globe = dynamic(() => import("@components/SupportedLeagues/Globe"), {
  ssr: false,
});

// ErrorBoundary to gracefully handle render errors
class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error rendering SupportedLeagues:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // If there's an error, return null to not render the section
      return null;
    }
    return this.props.children;
  }
}

const images = {
  light: [
    { src: "/images/leagues/premier-league.png", alt: "Premier League Logo" },
    { src: "/images/leagues/laliga.png", alt: "La Liga Logo" },
    { src: "/images/leagues/bundesliga.png", alt: "Bundesliga Logo" },
    { src: "/images/leagues/ligue-1.png", alt: "French Ligue 1 Logo" },
    { src: "/images/leagues/serie-a.png", alt: "Italy Serie A Logo" },
    {
      src: "/images/leagues/champions-league.png",
      alt: "UEFA Champions League Logo",
    },
    {
      src: "/images/leagues/nations-league.png",
      alt: "UEFA Nations League Logo",
    },
    { src: "/images/leagues/copa-america.png", alt: "Copa America Logo" },
    { src: "/images/leagues/super-cup.png", alt: "UEFA Super Cup Logo" },
    { src: "/images/leagues/mls.png", alt: "MLS Logo" },
  ],
  dark: [
    {
      src: "/images/leagues/premier-league@dark.png",
      alt: "Premier League Logo",
    },
    { src: "/images/leagues/laliga.png", alt: "La Liga Logo" },
    { src: "/images/leagues/bundesliga@dark.png", alt: "Bundesliga Logo" },
    { src: "/images/leagues/ligue-1@dark.png", alt: "French Ligue 1 Logo" },
    { src: "/images/leagues/serie-a.png", alt: "Italy Serie A Logo" },
    {
      src: "/images/leagues/champions-league@dark.png",
      alt: "UEFA Champions League Logo",
    },
    {
      src: "/images/leagues/nations-league.png",
      alt: "UEFA Nations League Logo",
    },
    { src: "/images/leagues/copa-america.png", alt: "Copa America Logo" },
    { src: "/images/leagues/super-cup@dark.png", alt: "UEFA Super Cup Logo" },
    { src: "/images/leagues/mls.png", alt: "MLS Logo" },
  ],
};

export const SupportedLeagues = () => {
  const { resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || images[resolvedTheme as "light" | "dark"] === undefined) {
    return null;
  }

  return (
    <section
      className="flex flex-col gap-md overflow-hidden px-6 py-24"
      aria-labelledby="supported-leagues-heading"
    >
      <Container>
        <div className="flex flex-col-reverse items-center gap-md md:flex-row">
          <ErrorBoundary>
            <Stack align="center" className="w-1/2">
              <div
                className="relative size-128 md:-ml-48 md:mt-none lg:mx-auto"
                data-aos="fade-up"
              >
                <DotPattern
                  width={16}
                  height={16}
                  cr={2}
                  cy={2}
                  cx={2}
                  className={cn(
                    "[mask-image:radial-gradient(300px_circle_at_center,white,transparent)]",
                    "fill-gray-400/40",
                  )}
                />
                <Globe images={images[resolvedTheme as "light" | "dark"]} />
              </div>
            </Stack>
          </ErrorBoundary>
          <Stack spacing="xxl" data-aos="fade-up">
            <header className="flex max-w-xl flex-col items-start gap-md">
              <Tag size="md" variant="neutral" as="p">
                Supported Leagues
              </Tag>
              <Title styleAs="h1" as="h2" id="supported-leagues-heading">
                Explore Soccer on a Global Scale
              </Title>
              <Title type="subtitle" as="h3" color="tertiary">
                Gain access to comprehensive predictions for all your favorite
                competitions and teams.
              </Title>
            </header>
            <Button
              color="primary"
              size="medium"
              data-aos="zoom-in"
              data-aos-delay="200"
              aria-label="View and explore upcoming tips"
              as="link"
              href={dashboardLink}
              tabIndex={0}
              onClick={() =>
                sendGTMEvent({
                  event: "cta_click",
                  value: "supported_leagues",
                  ...getUtmParams(),
                })
              }
            >
              Upcoming Tips
            </Button>
          </Stack>
        </div>
      </Container>
    </section>
  );
};
