"use client";

import { motion } from "framer-motion";
import { Stack } from "@ui/layout";
import { Button, Icon, Tag, Title } from "@ui/elements";
import React, { FC, PropsWithChildren, useState } from "react";
import { cn } from "@/lib/utils";
import { useDeviceType } from "@hooks/useDeviceType";
import { Tab } from "@components/Tab/Tab";
import { faqItems } from "@components/FAQ/faq-items";
import { Container } from "@components/Container/Container";

export type FAQItem = {
  question: string;
  content: React.ReactNode;
  type: "general" | "tips" | "strategies" | "subscription" | "support";
  answer: string;
};

type FAQItemProps = PropsWithChildren<{
  question: string;
  active?: boolean;
  onChange?: () => void;
}>;

const FAQItem: FC<FAQItemProps> = ({
  question,
  active,
  children,
  onChange,
}) => {
  const [enableScrolling, setEnableScrolling] = useState(false);
  const deviceType = useDeviceType();

  const maxHeight = deviceType === "desktop" ? 800 : 1500;

  return (
    <article className="flex w-full flex-col items-start gap-none rounded-lg border border-gray-200 dark:border-neutral-700">
      <Stack
        className={cn(
          "group w-full max-w-full cursor-pointer justify-between rounded-lg bg-white p-4 pl-5 pr-2.5 transition-colors hover:bg-gray-100 active:bg-gray-200 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:active:bg-neutral-700",
          {
            "rounded-bl-none rounded-br-none bg-gray-100 dark:bg-neutral-800":
              active,
          },
        )}
        role="button"
        direction="horizontal"
        aria-expanded={active}
        aria-controls={`faq-content-${question}`}
        align="center"
        onClick={() => {
          setEnableScrolling(false);
          onChange?.();
        }}
      >
        <h4
          className="flex-1 select-none text-sm text-gray-800 lg:text-base dark:text-neutral-200"
          id={`faq-heading-${question}`}
        >
          {question}
        </h4>
        <motion.div
          className="flex size-5 flex-none items-center justify-center bg-transparent lg:mr-2"
          animate={{ rotate: active ? 45 : 0 }}
          transition={{ duration: 0.2 }}
          aria-label={active ? "Collapse answer" : "Expand answer"}
        >
          <Icon icon="Plus" className="size-4 lg:size-5" />
        </motion.div>
      </Stack>

      <motion.div
        initial={{ maxHeight: 0, opacity: 0 }}
        role="region"
        aria-labelledby={`faq-heading-${question}`}
        id={`faq-content-${question}`}
        animate={{
          maxHeight: active ? maxHeight : 0,
          opacity: active ? 1 : 0,
        }}
        transition={{ duration: 0.3, ease: [0.4, 0, 0.2, 1] }}
        className={cn("max-w-full", {
          "overflow-auto": enableScrolling && active,
          "overflow-hidden": !enableScrolling || !active,
        })}
        onAnimationComplete={() => {
          if (active) {
            setEnableScrolling(true);
          }
        }}
        style={{ willChange: "max-height, opacity" }}
      >
        <div className="prose prose-sm prose-gray max-w-none p-5 dark:prose-neutral dark:prose-invert">
          {children}
        </div>
      </motion.div>
    </article>
  );
};

type FAQGroupProps = {
  items: FAQItem[];
  activeQuestion: string;
  setActiveQuestion: (q: string) => void;
};

const FAQGroup: FC<FAQGroupProps> = ({
  items,
  activeQuestion,
  setActiveQuestion,
}) => {
  return (
    <div className="flex flex-col gap-md">
      {items.map((item) => (
        <FAQItem
          key={`faq-item-${item.question}`}
          active={activeQuestion === item.question}
          onChange={() =>
            setActiveQuestion(
              activeQuestion === item.question ? "" : item.question,
            )
          }
          question={item.question}
        >
          {item.content}
        </FAQItem>
      ))}
    </div>
  );
};

export const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState<string>(
    "general-information",
  );
  const [activeTab, setActiveTab] = useState("general-information");

  return (
    <section
      className="relative flex max-w-[100vw] flex-col items-center gap-md overflow-hidden px-6 py-14 md:overflow-auto lg:py-32"
      aria-labelledby="faq-heading"
      id="faq"
    >
      <Container>
        <div className="mx-auto flex max-w-5xl flex-col gap-xxxl">
          <header className="flex flex-col items-start gap-md">
            <Tag size="md" variant="neutral">
              Help Center
            </Tag>
            <Title as="h2" styleAs="h1" id="faq-heading">
              <span className="sr-only">Frequently Asked Questions</span>
              Got any <strong className="text-primary">Questions?</strong>
            </Title>
            <Title
              as="h3"
              type="subtitle"
              color="tertiary"
              className="max-w-2xl font-light leading-relaxed tracking-wide"
            >
              We got you covered. Check out Frequently Asked Questions or
              contact us directly.
            </Title>
          </header>
          <Stack className="w-full flex-1" spacing="xxl">
            <div className="mx-auto flex w-full gap-xxl">
              <Tab
                value={activeTab}
                onChange={setActiveTab}
                tabId={`faq-tab`}
                asideClass="md:sticky md:top-28 lg:top-40 h-fit flex-none"
                contentClass="p-none bg-transparent dark:bg-transparent w-full max-w-2xl ml-auto md:mt-none mt-2"
                items={[
                  {
                    name: "general-information",
                    label: "General Information",
                    icon: "Info",
                    content: (
                      <FAQGroup
                        activeQuestion={activeQuestion}
                        setActiveQuestion={setActiveQuestion}
                        items={faqItems.filter((e) => e.type === "general")}
                      />
                    ),
                  },
                  {
                    name: "tips-and-predictions",
                    label: "Tips and Predictions",
                    icon: "Lightbulb",
                    content: (
                      <FAQGroup
                        activeQuestion={activeQuestion}
                        setActiveQuestion={setActiveQuestion}
                        items={faqItems.filter((e) => e.type === "tips")}
                      />
                    ),
                  },
                  {
                    name: "strategy-bankroll",
                    label: "Betting Strategies & Bankroll",
                    icon: "Strategy",
                    content: (
                      <FAQGroup
                        activeQuestion={activeQuestion}
                        setActiveQuestion={setActiveQuestion}
                        items={faqItems.filter((e) => e.type === "strategies")}
                      />
                    ),
                  },
                  {
                    name: "subscription-payments",
                    label: "Subscription & Payments",
                    icon: "CreditCard",
                    content: (
                      <FAQGroup
                        activeQuestion={activeQuestion}
                        setActiveQuestion={setActiveQuestion}
                        items={faqItems.filter(
                          (e) => e.type === "subscription",
                        )}
                      />
                    ),
                  },
                  {
                    name: "technical-support",
                    label: "Technical & Support",
                    icon: "Gear",
                    content: (
                      <FAQGroup
                        activeQuestion={activeQuestion}
                        setActiveQuestion={setActiveQuestion}
                        items={faqItems.filter((e) => e.type === "support")}
                      />
                    ),
                  },
                ]}
              />
            </div>
            <Button
              color="primary"
              size="medium"
              data-aos="fade-up"
              className="lg:hidden"
              as="link"
              href="#contact"
            >
              Contact Us
            </Button>
          </Stack>
        </div>
      </Container>
    </section>
  );
};
