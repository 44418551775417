import React from "react";
import { StatusColor } from "@utils";
import { cn } from "@/lib/utils";

interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  /** The content of the tag */
  children: React.ReactNode;
  /** Color variant */
  variant?: StatusColor;
  /** Size of the tag */
  size?: "sm" | "md" | "lg";
  /** If true, the tag will have rounded corners */
  rounded?: boolean;
  /** Optional icon to display on the left */
  icon?: React.ReactNode;
  /** Additional CSS classes */
  className?: string;
  as?: "span" | "p";
}

export const Tag: React.FC<TagProps> = ({
  children,
  variant,
  size = "md",
  rounded = false,
  icon,
  className = "",
  as = "span",
  ...props
}) => {
  const baseStyles = "inline-flex items-center font-medium";
  const TagComponent = as;

  const sizeStyles: Record<string, string> = {
    sm: "text-xs px-2 py-0.5",
    md: "text-xs md:text-sm px-3 py-1.5 md:px-4",
    lg: "text-base px-4 py-1.5",
  };

  const variantStyles: Record<string, string> = {
    primary: "bg-primary text-white",
    secondary: "bg-secondary text-gray-800",
    success: "bg-green-500/20 text-green-800 dark:text-neutral-300",
    warning: "bg-yellow-500/20 text-yellow-800 dark:text-neutral-300",
    info: "bg-blue-500/20 text-blue-800 dark:text-neutral-300",
    neutral: "bg-gray-500/10 dark:bg-neutral-800 dark:text-neutral-300",
    danger: "bg-red-500/20 text-danger-800",
  };

  const shapeStyles = rounded ? "rounded-full" : "rounded";

  return (
    <TagComponent
      className={cn(
        baseStyles,
        sizeStyles[size],
        shapeStyles,
        variant && variantStyles[variant],
        className,
      )}
      {...props}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </TagComponent>
  );
};
