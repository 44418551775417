"use client";
import Image, { StaticImageData } from "next/image";
import {
  isImageSlide,
  Lightbox,
  RenderSlideProps,
  Slide,
  useLightboxProps,
  useLightboxState,
} from "yet-another-react-lightbox";
import { Zoom } from "yet-another-react-lightbox/plugins";

import { FC, useState } from "react";
import { StaticImport } from "next/dist/shared/lib/get-img-props";

function isNextJsImage(slide: Slide) {
  return (
    isImageSlide(slide) &&
    typeof slide.width === "number" &&
    typeof slide.height === "number"
  );
}

const LightBoxSlide: FC<RenderSlideProps> = ({ slide, offset, rect }) => {
  const {
    on: { click },
  } = useLightboxProps();

  const { currentIndex } = useLightboxState();

  if (!slide.height || !slide.width) return null;
  if (!isNextJsImage(slide)) return undefined;

  // Calculate dimensions based on the container and image's natural size
  const width = Math.min(slide.width, rect.width);
  const height = Math.min(slide.height, rect.height);

  return (
    <div
      style={{
        position: "relative",
        width,
        height,
        margin: "auto",
      }}
    >
      <Image
        src={slide as StaticImport}
        alt=""
        fill
        loading="eager"
        className="rounded-xl"
        draggable={false}
        style={{
          objectFit: "contain", // Preserve aspect ratio
          cursor: click ? "pointer" : undefined,
        }}
        sizes={`${Math.ceil((width / window.innerWidth) * 100)}vw`}
        onClick={
          offset === 0 ? () => click?.({ index: currentIndex }) : undefined
        }
      />
    </div>
  );
};

type LightBoxProps = {
  images?: StaticImageData[];
  imageClass?: string;
  alt: string;
};

export const LightBox: FC<LightBoxProps> = ({ images, imageClass, alt }) => {
  const [open, setOpen] = useState(false);

  const showNavigation = (images?.length ?? 0) > 1; // Check if more than one image

  return (
    <span className="relative">
      {(images?.length || 0) > 1 && (
        <span
          className="absolute right-1 top-[2px] flex size-5 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 text-xs leading-none ring-2 ring-white dark:bg-neutral-700 dark:ring-gray-800"
          onClick={() => setOpen(true)}
        >
          {images?.length}
        </span>
      )}
      {!!images?.length && (
        <Image
          src={images[0]}
          alt={alt}
          onClick={() => setOpen(true)}
          className={imageClass}
          width={60}
          height={60}
        />
      )}
      <Lightbox
        carousel={{
          imageFit: "contain",
          finite: !showNavigation, // Disable infinite scrolling if only one image
        }}
        zoom={{
          maxZoomPixelRatio: 2,
          zoomInMultiplier: 1.25,
        }}
        plugins={[Zoom]}
        open={open}
        close={() => setOpen(false)}
        slides={images}
        render={{ slide: LightBoxSlide }}
        styles={{
          container: {
            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(5px)",
          },
          button: {
            filter: "none",
          },
        }}
      />
    </span>
  );
};
