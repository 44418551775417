"use client";
import { useState } from "react";
import { Button, Icon, Tag, Title } from "@ui/elements";
import { Stack } from "@ui/layout";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { sendGTMEvent } from "@next/third-parties/google";
import { getUtmParams, useUtmLink } from "@utils";

type BillingCycle = "weekly" | "monthly";

export const PricingTable = () => {
  const [billingCycle, setBillingCycle] = useState<BillingCycle>("monthly");
  const dashboardLink = useUtmLink(process.env.NEXT_PUBLIC_APP_URL || "");

  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === "monthly" ? "weekly" : "monthly");
  };

  return (
    <section
      className="flex flex-col items-start gap-xxxl px-6 py-14 lg:py-24"
      id="pricing"
      aria-labelledby="pricing-heading"
    >
      <header className="mx-auto flex flex-col items-center justify-center gap-md">
        <Tag size="md" variant="neutral">
          Pricing Plan
        </Tag>
        <Title
          as="h2"
          styleAs="h1"
          align="center"
          className="w-full"
          id="pricing-heading"
        >
          Pricing Made Simple
        </Title>
        <Title
          as="h3"
          type="subtitle"
          color="tertiary"
          className="max-w-2xl text-center font-light leading-relaxed tracking-wide"
        >
          Find the perfect plan for your betting goals—whether you’re just
          starting out or a seasoned expert.
        </Title>
      </header>

      <div className="flex w-full flex-col gap-md md:gap-xxl">
        <Stack
          direction="horizontal"
          data-aos="fade-up"
          align="center"
          className="w-full justify-center pb-6"
          role="group"
          aria-label="Billing Cycle Toggle"
        >
          <button
            type="button"
            className={classNames("cursor-pointer text-lg tracking-wide", {
              "font-semibold dark:text-neutral-200": billingCycle === "weekly",
              "text-gray-400": billingCycle === "monthly",
            })}
            onClick={() => setBillingCycle("weekly")}
            aria-pressed={billingCycle === "weekly"}
          >
            Weekly
          </button>

          {/* Toggle Switch */}
          <label
            htmlFor="billing-toggle"
            className="relative inline-flex cursor-pointer items-center"
          >
            <input
              type="checkbox"
              id="billing-toggle"
              className="peer sr-only"
              checked={billingCycle === "monthly"}
              onChange={toggleBillingCycle}
              aria-label="Toggle Billing Cycle"
            />
            <div className="peer-focus:ring-primary-purple peer h-6 w-11 rounded-full bg-gray-300 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-neutral-700 after:bg-neutral-700 after:transition-all after:content-[''] peer-checked:bg-gradient-to-r peer-checked:from-[#80EE98] peer-checked:via-[#46DFB1] peer-checked:to-[#09D1C7] peer-checked:after:translate-x-full peer-checked:after:border-neutral-700 dark:bg-neutral-700 dark:after:bg-neutral-800"></div>
          </label>

          <button
            type="button"
            className={classNames("cursor-pointer text-lg tracking-wide", {
              "font-semibold dark:text-neutral-200": billingCycle === "monthly",
              "text-gray-400": billingCycle === "weekly",
            })}
            onClick={() => setBillingCycle("monthly")}
            aria-pressed={billingCycle === "monthly"}
          >
            Monthly
          </button>
        </Stack>

        <div className="mx-auto grid max-w-md grid-cols-1 gap-lg md:max-w-7xl md:grid-cols-2 lg:grid-cols-3 lg:gap-xxl">
          {/* Free Pricing Card */}
          <article
            className="flex flex-col rounded-lg border border-gray-200 p-7 shadow-sm sm:p-10 dark:border-neutral-700 dark:bg-neutral-800"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h3 className="text-xl font-bold text-gray-800 xl:text-2xl dark:text-neutral-200">
              Free
            </h3>
            <p className="mt-4 text-4xl font-extrabold text-gray-800 xl:text-5xl dark:text-neutral-200">
              €0
            </p>
            <p className="text-md mt-2 text-gray-500 xl:text-lg dark:text-neutral-400">
              Perfect package for beginners and casual bettors.
            </p>
            <ul className="mt-6 flex-1 space-y-4">
              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  Up to 3 matches/day
                </span>
              </li>
              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  In-depth Match Analysis
                </span>
              </li>
              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  No Commitment
                </span>
              </li>
            </ul>
            <div className="mt-8">
              <Button
                size="medium"
                color="neutral"
                className="w-full"
                aria-label="Get started with Free Plan"
                as="link"
                href={dashboardLink}
                onClick={() =>
                  sendGTMEvent({
                    event: "plan_click",
                    value: "pricing_free",
                    ...getUtmParams(),
                  })
                }
              >
                Get Started
              </Button>
            </div>
          </article>

          {/* Standard Pricing Card */}
          <article className="order-first md:col-span-2 lg:order-none lg:col-span-1 lg:-mx-4 lg:-my-6">
            <div
              className="mx-auto flex flex-col overflow-hidden rounded-lg bg-[linear-gradient(to_right,rgba(128,238,152,0.7),rgba(70,223,177,0.7),rgba(9,209,199,0.7))] p-7 text-gray-800 shadow-sm md:max-w-lg md:p-10 dark:bg-[linear-gradient(to_right,rgba(128,238,152,0.8),rgba(70,223,177,0.8),rgba(9,209,199,0.8))]"
              data-aos="fade-up"
            >
              <Stack
                direction="horizontal"
                className="justify-between"
                align="center"
              >
                <h3 className="text-xl font-bold text-gray-800 xl:text-2xl">
                  Standard
                </h3>
                <AnimatePresence mode="wait" initial={false}>
                  {billingCycle === "monthly" && (
                    <motion.div
                      key="most-affordable-tag"
                      initial={{
                        opacity: 0,
                        transform: "translateX(100%)",
                      }}
                      animate={{
                        opacity: 1,
                        transform: "translateX(0)",
                      }}
                      exit={{
                        opacity: 0,
                        transform: "translateX(100%)",
                      }}
                      transition={{ duration: 0.1 }}
                    >
                      <Tag
                        size="md"
                        className="flex gap-xs truncate border border-none bg-white/30 text-gray-800 backdrop-blur-md"
                        aria-label="Most Affordable Plan"
                      >
                        <Icon
                          icon="Wallet"
                          className="-ml-1 size-5"
                          weight="fill"
                          aria-label="Wallet Icon representing most affordable choice"
                        />
                        <span className="text-sm xl:text-base">
                          Most Affordable
                        </span>
                      </Tag>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Stack>
              <AnimatePresence mode="wait" initial={false}>
                <motion.p
                  key={billingCycle}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{ duration: 0.1 }}
                  className="mt-4 text-4xl font-extrabold xl:text-5xl"
                >
                  € {billingCycle === "monthly" ? 14.9 : 4.5}
                  <span className="text-xl font-medium xl:text-2xl">
                    /{billingCycle === "monthly" ? "Month" : "Week"}
                  </span>
                </motion.p>
              </AnimatePresence>
              <p className="text-md mt-2 text-gray-600 xl:text-lg dark:text-neutral-700">
                Ideal for bettors who treat betting as an investment strategy.
              </p>
              <ul className="mt-6 flex-1 space-y-4">
                <li className="flex items-center">
                  <Icon
                    icon="CheckCircle"
                    weight="fill"
                    className="size-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm xl:text-base">
                    Unlimited tips
                  </span>
                </li>
                <li className="flex items-center">
                  <Icon
                    icon="CheckCircle"
                    weight="fill"
                    className="size-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm xl:text-base">
                    24/7 expert support
                  </span>
                </li>
                <li className="flex items-center">
                  <Icon
                    icon="CheckCircle"
                    weight="fill"
                    className="size-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm xl:text-base">
                    Bankroll Management
                  </span>
                </li>
                <li className="flex items-center">
                  <Icon
                    icon="CheckCircle"
                    weight="fill"
                    className="size-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm xl:text-base">
                    Custom Betting Strategies
                  </span>
                </li>
                <li className="flex items-center">
                  <Icon
                    icon="CheckCircle"
                    weight="fill"
                    className="size-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm xl:text-base">
                    Personalisation
                  </span>
                </li>
              </ul>
              <div className="mt-8">
                <Button
                  size="large"
                  color="primary"
                  className="w-full"
                  aria-label="Get Started with Standard Plan"
                  as="link"
                  href={dashboardLink}
                  onClick={() =>
                    sendGTMEvent({
                      event: "plan_click",
                      value: "pricing_standard",
                      ...getUtmParams(),
                    })
                  }
                >
                  Get Started
                </Button>
              </div>
            </div>
          </article>

          {/* Enterprise Pricing Card */}
          <article
            className="flex flex-col rounded-lg border border-gray-200 p-7 shadow-sm md:p-10 dark:border-neutral-700 dark:bg-neutral-800"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h3 className="text-xl font-bold text-gray-800 xl:text-2xl dark:text-neutral-200">
              Enterprise
            </h3>
            <p className="text-md mt-2 text-gray-500 xl:text-lg dark:text-neutral-400">
              For professionals and businesses seeking to integrate AI-driven
              betting solutions.
            </p>
            <ul className="mt-6 flex-1 space-y-4">
              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  Everything in Standard Plan
                </span>
              </li>
              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  Developer API Access
                </span>
              </li>
              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  Custom Integrations
                </span>
              </li>

              <li className="flex items-center">
                <Icon
                  icon="CheckCircle"
                  weight="fill"
                  className="size-5 flex-shrink-0 text-gray-800 dark:text-neutral-200"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm xl:text-base dark:text-neutral-200">
                  Marketing and Automation Tools
                </span>
              </li>
            </ul>
            <div className="mt-8">
              <Button
                size="medium"
                color="neutral"
                className="w-full"
                as="link"
                href="#contact"
                aria-label="Contact Us for Enterprise Plan" // ARIA label for clarity
                onClick={() =>
                  sendGTMEvent({
                    event: "plan_click",
                    value: "pricing_enterprise",
                    ...getUtmParams(),
                  })
                }
              >
                Contact Us
              </Button>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};
